$(function() {
  if ($('body.stylists-affiliate_rewards.index').length < 1) return

  // infinite scroll
  if ($('.js-affiliate-reward-box').length === 10) {
    const affiliateRewardIntervalId = window.setInterval(function() {
      if ($('.js-affiliate-reward-box:eq(-1)').is(':appeared')) {
        window.clearInterval(affiliateRewardIntervalId)
        $('.js-infinite-spinner').show()
        $.ajax({
          type: 'GET',
          url: '/paging/stylists/affiliate_rewards',
          dataType: 'script',
          data: {
            page: 1,
          },
        })
      }
    }, 2000)
  }
})
