$(function() {
  if ($('body.users.collections').length < 1) return

  $('.js-infinite-spinner').show()
  $.ajax({
    type: 'GET',
    url: '/paging/users/collections',
    dataType: 'script',
    data: {
      page: 0,
      public_uid: location.pathname.split('/')[2],
    },
  })

  $(document).on('click', '.js-collection-btn', function() {
    $('.js-edit-collection-btn').attr('href', $(this).data('edit-path'))
    $('.js-collection-edit-modal').modal('show')
  })
})
