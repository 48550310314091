$(function() {
  if ($('body.all-notifications.index').length < 1) return

  // unread-notification
  $(document).on('click', 'a[data-read-notification-path]', function(e) {
    e.preventDefault()
    const $this = $(this)
    $.ajax({
      method: 'PATCH',
      url: $this.data('read-notification-path'),
    }).done(function() {
      window.location.href = $this.attr('href')
    }).fail(function() {
      window.location.reload()
    })
  })

  // infinite scroll
  if ($('.js-normal-notifications').length) {
    const normalNotificationIntervalId = window.setInterval(function() {
      if ($('.js-normal-notification-link:eq(-1)').is(':appeared')) {
        window.clearInterval(normalNotificationIntervalId)
        $('.js-infinite-spinner').show()
        $.ajax({
          type: 'GET',
          url: '/paging/normal_notifications',
          dataType: 'script',
          data: {
            page: 1,
          },
        })
      }
    }, 2000)
  }
  if ($('.js-special-notifications').length) {
    const specialNotificationIntervalId = window.setInterval(function() {
      if ($('.js-special-notification-link:eq(-1)').is(':appeared')) {
        window.clearInterval(specialNotificationIntervalId)
        $('.js-infinite-spinner').show()
        $.ajax({
          type: 'GET',
          url: '/paging/special_notifications',
          dataType: 'script',
          data: {
            page: 1,
          },
        })
      }
    }, 2000)
  }
})
