$(function() {
  if ($('body.styles.show').length < 1) return

  const $controlModal = $('.js-control-modal')
  // control-modal
  $('.js-top-header-right-div').on('click', function() {
    $controlModal.modal('show')
  })

  // copy-url-span
  $('.js-copy-url-span').on('click', function() {
    const $input = $('<input>')
    $(this).append($input)
    $input.val(window.location.href).select()
    document.execCommand('copy')
    $input.remove()
    $controlModal.modal('hide')
  })

  // destroy-style-link
  $('.js-destroy-style-link').on('click', function(e) {
    e.preventDefault()
    if (!window.confirm(window.App.messages.confirmDestroy)) return
    $.ajax({
      method: 'DELETE',
      url: $(this).attr('href'),
      dataType: 'script',
    })
    $controlModal.modal('hide')
    $('.js-spinner-modal').modal('show')
  })

  // product-view
  const { apiGatewayUrl, apiGatewayApiKey, siteId, userId, styleId, productCodes } = window.gon
  App.util.sendProductView({
    apiGatewayUrl, apiGatewayApiKey, siteId, userId, productCodes, styleId,
  })

  // init
  App.util.snapSwiperInit()
})
