$(function() {
  if ($('body.users.proposals').length < 1) return

  $('.js-infinite-spinner').show()
  $.ajax({
    type: 'GET',
    url: '/paging/users/proposals',
    dataType: 'script',
    data: {
      page: 0,
      public_uid: location.pathname.split('/')[2],
    },
  })

  $(document).on('click', '.js-proposal-btn', function() {
    $('.js-edit-proposal-btn').attr('href', $(this).data('edit-path'))
    $('.js-control-modal').modal('show')
  })
})
