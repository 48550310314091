$(function() {
  if ($('body.all-contests.index').length < 1) return

  let loadPage = 0
  function setContestInfiniteLoader() {
    const intervalID = window.setInterval(function() {
      if ($('.js-list-contest:eq(-2)').is(':appeared')) {
        window.clearInterval(intervalID)
        $('.js-infinite-spinner').show()
        getContests(loadPage++)
      }
    }, 2000)
  }

  function getContests(page) {
    $.ajax({
      type: 'GET',
      url: '/paging/contests',
      dataType: 'script',
      data: {
        page: page,
      },
    }).done(function() {
      setContestInfiniteLoader()
    }).always(function() {
      $('.js-infinite-spinner').hide()
    })
  }

  $('.js-infinite-spinner').show()
  getContests(loadPage++)
})
