$(function() {
  if ($('body.users.styles').length < 1) return

  $('.js-infinite-spinner').show()
  $.ajax({
    type: 'GET',
    url: '/paging/users/styles',
    dataType: 'script',
    data: {
      page: 0,
      public_uid: location.pathname.split('/')[2],
    },
  })
})
