$(function() {
  const $copiedUrlModal = $('.js-copied-url-modal')
  $('.js-copy-url-btn').on('click', function() {
    const $input = $('<input>'),
      $this = $(this),
      copyUrl = $this.data('copy-url')
    $this.append($input)
    $input.val(copyUrl || window.location.href).select()
    document.execCommand('copy')
    $input.remove()
    $copiedUrlModal.modal('show')
  })
})
