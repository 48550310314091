$(function() {
  if ($('body.shares.new').length < 1) return

  $('.js-create-share-btn').on('click', function() {
    $.ajax({
      method: 'POST',
      url: '/shares',
      dataType: 'script',
      data: {
        share: {
          product_id: window.gon.productId,
        },
      },
    })
    $('.js-spinner-modal').modal('show')
  })

  // init
  App.util.photoSwiperInit()
})
