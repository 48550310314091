$(function() {
  if ($('body.infos.edit_profile').length < 1) return

  // background
  const $backgroundModal = $('.js-background-modal')
  let backgroundCroppieImg = null,
    backgroundSrc = null
  $('#js-background-file').on('change', function(event) {
    const file = event.target.files[0]
    if (!file) return
    const reader = new FileReader()
    reader.onload = function() {
      backgroundSrc = reader.result
      if (backgroundCroppieImg) {
        backgroundCroppieImg.croppie('destroy')
        backgroundCroppieImg.attr('src', '')
      }
      $backgroundModal.modal('show')
    }
    reader.readAsDataURL(file)
    event.target.value = null
  })
  $backgroundModal.on('shown.bs.modal', function() {
    backgroundCroppieImg = $('.js-background-modal-img').attr('src', backgroundSrc).croppie({
      viewport: { width: 320, height: 120, type: 'square' },
      boundary: { width: 320, height: 320 },
      enableExif: true,
      enforceBoundary: false,
    })
  })
  $('.js-background-modal-btn').on('click', function() {
    backgroundCroppieImg.croppie('result', { type: 'base64', size: { width: 750, height: 240 } }).then(function(cropped) {
      $('.js-background-img').attr('src', cropped).removeClass('blank')
      $('#user_background').prop('disabled', false).val(cropped)
      $backgroundModal.modal('hide')
    })
  })

  // avatar
  const $avatarModal = $('.js-avatar-modal')
  let avatarCroppieImg = null,
    avatarSrc = null
  $('#js-avatar-file').on('change', function(event) {
    const file = event.target.files[0]
    if (!file) return
    const reader = new FileReader()
    reader.onload = function() {
      avatarSrc = reader.result
      if (avatarCroppieImg) {
        avatarCroppieImg.croppie('destroy')
        avatarCroppieImg.attr('src', '')
      }
      $avatarModal.modal('show')
    }
    reader.readAsDataURL(file)
    event.target.value = null
  })
  $avatarModal.on('shown.bs.modal', function() {
    avatarCroppieImg = $('.js-avatar-modal-img').attr('src', avatarSrc).croppie({
      viewport: { width: 320, height: 320, type: 'square' },
      boundary: { width: 320, height: 320 },
      enableExif: true,
      enforceBoundary: false,
    })
  })
  $('.js-avatar-modal-btn').on('click', function() {
    avatarCroppieImg.croppie('result', { type: 'base64', size: { width: 180, height: 180 } }).then(function(cropped) {
      $('.js-avatar-img').attr('src', cropped)
      $('#user_avatar').prop('disabled', false).val(cropped)
      $avatarModal.modal('hide')
    })
  })

  // personal color
  const $colorLabel = $('.js-personal-color-label')
  $colorLabel.on('click', function() {
    $colorLabel.removeClass('active')
    $(this).addClass('active')
    $('#user_personal_color').val($(this).data('personal-color'))
  })

  // body frame
  const $frameLabel = $('.js-body-frame-label')
  $frameLabel.on('click', function() {
    $frameLabel.removeClass('active')
    $(this).addClass('active')
    $('#user_body_frame').val($(this).data('body-frame'))
  })
})
