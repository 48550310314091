$(function() {
  if ($('body.stylists-rewards.index').length < 1) return

  // reward_balance
  const $newTransferLink = $('.js-new-transfer-link'),
    balance = $newTransferLink.data('balance'),
    bankFee = 700,
    $textModalBody = $('.js-text-modal-body'),
    invalidTextP = '<p>振込申請可能金額が700円以下のため申請できません</p>',
    $textModal = $('.js-text-modal')
  $newTransferLink.on('click', function(e) {
    if (bankFee < balance) return
    e.preventDefault()
    $textModalBody.html(invalidTextP)
    $textModal.modal('show')
  })
})
