$(function() {
  if ($('body.users-passwords.new').length < 1) return

  // inputs
  const $email = $('.js-email')

  // create-btn
  const $textModal = $('.js-text-modal'),
    $textModalBody = $('.js-text-modal-body')
  $('.js-create-btn').on('click', function() {
    const validationTextAry = []
    if (!$email.val()) {
      validationTextAry.push('メールアドレスを入力してください')
    } else if (!App.util.validEmail($email.val())) {
      validationTextAry.push('メールアドレスの形式が正しくありません')
    }

    if (validationTextAry.length) {
      $textModalBody.html(validationTextAry.map(function(text) {
        return `<p>${text}</p>`
      }).join(''))
      $textModal.modal('show')
    } else {
      $.ajax({
        method: 'POST',
        url: '/users/password',
        dataType: 'script',
        data: {
          user: {
            email: $email.val(),
          },
        },
      })
      $('.js-spinner-modal').modal('show')
    }
  })
})
