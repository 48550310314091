$(function() {
  if ($('body.styles.create_confirm, body.styles.update_confirm').length < 1) return

  $('.js-style-snap-img').each(function(index) {
    const $this = $(this)
    if (!$this.attr('src')) return false
    $this.one('load', function() {
      const img = $this.get(0),
        canvas = document.createElement('canvas')
      canvas.width = img.naturalWidth
      canvas.height = img.naturalHeight
      canvas.getContext('2d').drawImage(img, 0, 0)
      if (index === 0) {
        $('input[name="style[snap]"]').val(canvas.toDataURL('image/png'))
      } else {
        $(`input[name="style[style_snaps_attributes][${index - 1}][snap]"]`).val(canvas.toDataURL('image/png'))
      }
    }).each(function() {
      if (this.complete) $(this).trigger('load')
    })
  })

  $('.js-submit-style-btn').on('click', function() {
    Rails.fire(document.querySelector('.js-style-form'), 'submit')
    $('.js-spinner-modal').modal('show')
  })

  $('.js-back-style-btn').on('click', function() {
    $('.js-is-back').val(true)
    $('.js-style-form').trigger('submit')
    $('.js-spinner-modal').modal('show')
  })

  // init
  App.util.snapSwiperInit()
})
