$(function() {
  if ($('body.users-followers.index').length < 1) return

  const $followerSearchInput = $('.js-follower-search-input'),
    hasOverFolloweeFollowsLimit = $followerSearchInput.hasClass('js-over-followee-follows-limit')
  let fetchedPaging = false
  $followerSearchInput.on('input', function() {
    const val = $(this).val()
    if (val) {
      $('.js-follower-user').each(function() {
        const $followerUser = $(this)
        if ($followerUser.data('username').includes(val) || $followerUser.data('nickname').includes(val)) {
          $followerUser.fadeIn(400)
        } else {
          $followerUser.hide()
        }
      })
    } else {
      $('.js-follower-user').each(function() {
        $(this).fadeIn(400)
      })
    }
  })
  // paging
  if (hasOverFolloweeFollowsLimit) {
    $followerSearchInput.one('focus', function() {
      if (fetchedPaging) return
      fetchPagingFolloweeFollows()
    })
    const followerUserIntervalId = window.setInterval(function() {
      if ($('.js-follower-user:eq(-2)').is(':appeared')) {
        window.clearInterval(followerUserIntervalId)
        if (fetchedPaging) return
        $('.js-infinite-spinner').show()
        fetchPagingFolloweeFollows()
      }
    }, 2000)
  }
  function fetchPagingFolloweeFollows() {
    $.ajax({
      type: 'GET',
      url: '/paging/users/followers',
      dataType: 'script',
    })
    fetchedPaging = true
  }
})
