$(function() {
  const $followNotSignInModal = $('.js-follow-not-sign-in-modal')
  $(document).on('click', '.js-follow-not-sign-in-btn', function() {
    $followNotSignInModal.modal('show')
  })

  $(document).on('click', '.js-create-follow-btn', function() {
    const $this = $(this)
    $this.fadeOut(600)
    $.ajax({
      method: 'POST',
      url: $this.data('path'),
    }).done(function() {
      $this.hide()
      $this.next('.js-destroy-follow-btn').removeClass('d-none').hide().fadeIn(600)
    }).fail(function() {
      window.alert(window.App.messages.errorAndTry)
    })
  })
  $(document).on('click', '.js-destroy-follow-btn', function() {
    const $this = $(this)
    if (!window.confirm('フォローを解除しますか？')) return
    $this.fadeOut(600)
    $.ajax({
      method: 'DELETE',
      url: $this.data('path'),
    }).done(function() {
      $this.hide()
      $this.prev('.js-create-follow-btn').removeClass('d-none').hide().fadeIn(600)
    }).fail(function() {
      window.alert(window.App.messages.errorAndTry)
    })
  })
})
