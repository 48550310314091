import './node_modules'
import './custom_bootstrap'

import './app'

import './shared/ask'
import './shared/ask_user'
import './shared/best_proposal'
import './shared/bottom_footer'
import './shared/card'
import './shared/check_product_modal'
import './shared/check_products_modal'
import './shared/collections_header'
import './shared/copied_url_modal'
import './shared/filter'
import './shared/fixed_bottom'
import './shared/follows'
import './shared/headers'
import './shared/home_site'
import './shared/items_collect'
import './shared/list_asks'
import './shared/list_backgrounds'
import './shared/list_collages'
import './shared/list_collections'
import './shared/list_contests'
import './shared/list_messages'
import './shared/list_privileges'
import './shared/list_products'
import './shared/list_proposals'
import './shared/list_shares'
import './shared/list_styles'
import './shared/not_sign_in_modal'
import './shared/notification_link'
import './shared/product_info'
import './shared/site_best'
import './shared/style_info'
import './shared/style_product'
import './shared/textarea_footer'
import './shared/textarea_footer_modal'
import './shared/transfer_box'
import './shared/user_info'
import './shared/user_info_brands'

import './asks/ask_delete_modal'
import './asks/best_proposals_show'
import './asks/proposals_collect'
import './asks/proposals_common'
import './asks_new'
import './asks_show'

import './all/asks_index'
import './all/collages_index'
import './all/collections_index'
import './all/collages_index'
import './all/contests_index'
import './all/notifications_index'
import './all/styles_index'
import './all/shares_index'

import './brands/cards_show'

import './collages_collect'
import './collages_common'
import './collages_confirm'
import './collages_new'
import './collages_show'

import './collections_collect'
import './collections_common'
import './collections_confirm'
import './collections_show'

import './contests_show'

import './infos_common'
import './infos_edit_profile'

import './pages_show'

import './privileges_show'

import './products_show'

import './proposals_show'

import './rankings_common'
import './rankings_stylist'

import './shares_collect'
import './shares_new'
import './shares_show'

import './sites/coins_index'
import './sites/coins_level_up'
import './sites/privileges_index'

import './styles_common'
import './styles_confirm'
import './styles_show'

import './stylists/admissions_show'
import './stylists/cards_show'
import './stylists/checkins_index'
import './stylists/rewards_common'
import './stylists/rewards_index'
import './stylists/transfers_common'
import './stylists/transfers_complete'
import './stylists/transfers_confirm'
import './stylists/transfers_index'
import './stylists/transfers_new'

import './users/bank_accounts_edit'
import './users/confirmations_new'
import './users/followees_index'
import './users/followers_index'
import './users/passwords_new'
import './users/passwords_edit'
import './users/registrations_new'
import './users/sessions_new'
import './users/shipping_addresses_common'
import './users/shipping_addresses_index'
import './users_common'
import './users_detail'
import './users_info'
import './users_menu'
