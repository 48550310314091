$(function() {
  if ($('body.collections.new, body.collections.edit').length < 1) return

  const siteId = window.gon.siteId
  const prefixedIdsKey = `collection_site${siteId}_prefixedIds`,
    productIdPhotoIdKey = `collection_site${siteId}_productIdPhotoId`,
    selectedItemListKey = `collection_site${siteId}_selectedItemList`,
    collectionNameKey = `collection_site${siteId}_collectionName`,
    collectionCaptionKey = `collection_site${siteId}_collectionCaption`,
    collectionTagsKey = `collection_site${siteId}_collectionTags`
  let prefixedIds = [],
    productIdPhotoId = {},
    selectedItemList = []
  function resetCollectionItemData() {
    prefixedIds = []
    productIdPhotoId = {}
    selectedItemList = []
    $('.js-collection-item-col').each(function() {
      const $this = $(this),
        prefixedId = $this.data('prefixed-id'),
        productId = $this.data('product-id')
      prefixedIds.push(prefixedId)
      if (productId) {
        productIdPhotoId[productId] = $this.data('id') || 0
      }
      selectedItemList.push({ prefixedId: prefixedId, src: $this.find('.js-collection-item-img').attr('src') })
    })
    sessionStorage.setItem(prefixedIdsKey, JSON.stringify(prefixedIds))
    sessionStorage.setItem(productIdPhotoIdKey, JSON.stringify(productIdPhotoId))
    sessionStorage.setItem(selectedItemListKey, JSON.stringify(selectedItemList))
  }

  function resetCollectionItems() {
    let productPhotoIdx = 0,
      productIdx = 0
    $('.js-collection-item-col').each(function(i) {
      const $this = $(this)
      $this.find('.js-item-number').text(i + 1)
      if ($this.data('prefixed-id').startsWith('productPhoto')) {
        $this.find('.js-input-product-photo-id').attr('name', `collection[collection_product_photos_attributes][${productPhotoIdx}][product_photo_id]`)
        $this.find('.js-input-product-photo-order').attr('name', `collection[collection_product_photos_attributes][${productPhotoIdx}][display_order]`)
        $this.find('.js-input-product-photo-order').attr('value', i + 1)
        productPhotoIdx++
      } else {
        $this.find('.js-input-product-id').attr('name', `collection[collection_products_attributes][${productIdx}][product_id]`)
        $this.find('.js-input-product-order').attr('name', `collection[collection_products_attributes][${productIdx}][display_order]`)
        $this.find('.js-input-product-order').attr('value', i + 1)
        productIdx++
      }
    })
  }

  const $collectionName = $('.js-collection-name'),
    $collectionCaption = $('.js-collection-caption'),
    maxCaptionLength = 800
  // letter-count
  const $captionLetterCount = $('.js-caption-letter-count')
  function updateCount() {
    const count = $collectionCaption.val().length
    $captionLetterCount.text(count > maxCaptionLength ? maxCaptionLength - count : count)
    if (count > maxCaptionLength) {
      $captionLetterCount.addClass('over')
    } else {
      $captionLetterCount.removeClass('over')
    }
  }
  $collectionName.on('input', function() {
    sessionStorage.setItem(collectionNameKey, $collectionName.val())
  })
  $collectionCaption.on('input', function() {
    updateCount()
    sessionStorage.setItem(collectionCaptionKey, $collectionCaption.val())
  })

  // init
  resetCollectionItemData()
  resetCollectionItems()
  if (sessionStorage.getItem(collectionNameKey)) $collectionName.val(sessionStorage.getItem(collectionNameKey))
  if (sessionStorage.getItem(collectionCaptionKey)) $collectionCaption.val(sessionStorage.getItem(collectionCaptionKey))
  updateCount()

  const $textModal = $('.js-text-modal'),
    $textModalBody = $('.js-text-modal-body')
  // item-remove-div
  const invalidItemsTextP = '<p>アイテムは1件以上必須のため削除できません</p>'
  $('.js-item-remove-div').on('click', function() {
    if (1 < $('.js-collection-item-col').length) {
      const $collectionItemCol = $(this).closest('.js-collection-item-col'),
        prefixedId = $collectionItemCol.data('prefixed-id'),
        productId = $collectionItemCol.data('product-id')
      prefixedIds = prefixedIds.filter(id => id !== prefixedId)
      sessionStorage.setItem(prefixedIdsKey, JSON.stringify(prefixedIds))
      if (productId) {
        delete productIdPhotoId[productId]
        sessionStorage.setItem(productIdPhotoIdKey, JSON.stringify(productIdPhotoId))
      }
      selectedItemList = selectedItemList.filter(selectedItem => selectedItem.prefixedId !== prefixedId)
      sessionStorage.setItem(selectedItemListKey, JSON.stringify(selectedItemList))
      $collectionItemCol.remove()
      resetCollectionItems()
    } else {
      $textModalBody.html(invalidItemsTextP)
      $textModal.modal('show')
    }
  })

  // sort
  new Sortable(document.querySelector('.js-collection-items'), {
    animation: 150,
    handle: '.js-collection-item-handle',
    onUpdate: function() {
      resetCollectionItemData()
      resetCollectionItems()
    },
  })

  // submit
  const invalidNameTextP = '<p>タイトルを入力してください</p>',
    invalidCaptionTextP = '<p>キャプションを入力してください</p>',
    overLengthCaptionTextP = `<p>キャプションは${maxCaptionLength}字以内です</p>`
  function validate() {
    if (!$collectionName.val().trim()) {
      $textModalBody.html(invalidNameTextP)
      $textModal.modal('show')
    } else if (!$collectionCaption.val().trim()) {
      $textModalBody.html(invalidCaptionTextP)
      $textModal.modal('show')
    } else if (maxCaptionLength < $collectionCaption.val().length) {
      $textModalBody.html(overLengthCaptionTextP)
      $textModal.modal('show')
    } else {
      return true
    }
    return false
  }

  // 最終確認画面へ遷移
  $('.js-confirm-collection-btn').on('click', function() {
    const collectionTags = $('.js-tag-name').map(function() {
      return $(this).text()
    }).get()
    sessionStorage.setItem(collectionTagsKey, JSON.stringify(collectionTags))
    if (validate()) {
      $('.js-collection-form').trigger('submit')
      $('.js-spinner-modal').modal('show')
    }
  })

  // 下書き保存
  $('.js-draft-collection-btn').on('click', function() {
    if (validate()) {
      $('.js-is-display').val(false)
      $('.js-is-confirm').val(false)
      Rails.fire(document.querySelector('.js-collection-form'), 'submit')
      $('.js-spinner-modal').modal('show')
    }
  })
})
