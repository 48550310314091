import { fabric } from 'fabric'

$(function() {
  if ($('body.collages.collect').length < 1) return

  const canvas = new fabric.Canvas('collage-canvas', {
    backgroundColor: 'rgb(255,255,255)',
    selection: false,
  })

  window.App.checkItems = function() {
    canvas.getObjects().forEach(obj => {
      $(`.js-collect-product-photo[data-product-id="${obj.productId}"][data-id="${obj.photoId}"] .js-collect-item-check`).attr('data-check', '1')
    })
  }

  const siteId = window.gon.siteId
  const collageImageKey = `collage_site${siteId}_collageImage`
  const collagePrefixedIdsKey = `collage_site${siteId}_collagePrefixedIds`
  let activeSex = window.gon.sex
  let activeBrandId = '0'
  let activeGroupId = window.gon.groupId
  let activeVarietyId = window.gon.varietyId
  let activeColorId = '0'
  let activeProductName = ''

  const $collectItems = $('.js-collect-items')
  const fetches = []
  function updateView(scrollBrand = false, scrollGroup = false, scrollVariety = false, scrollColor = false) {
    $('.js-item-sex').removeClass('active')
    $('.js-item-brand').removeClass('active')
    $('.js-item-group').removeClass('active')
    $('.js-item-varieties').removeClass('active')
    $('.js-item-variety').removeClass('active')
    $('.js-item-color').removeClass('active')
    $(`.js-item-sex[data-sex="${activeSex}"]`).addClass('active')
    $(`.js-item-brand[data-brand-id="${activeBrandId}"]`).addClass('active')
    $(`.js-item-group[data-group-id="${activeGroupId}"]`).addClass('active')
    $(`.js-item-varieties[data-group-id="${activeGroupId}"]`).addClass('active')
    $(`.js-item-varieties[data-group-id="${activeGroupId}"] .js-item-variety[data-variety-id="${activeVarietyId}"]`).addClass('active')
    $(`.js-item-color[data-color-id="${activeColorId}"]`).addClass('active')

    if (scrollBrand && $('.js-item-brand.active').length) $('.js-item-brands').scrollLeft(0).animate({ scrollLeft: $('.js-item-brand.active').position().left }, 600)
    if (scrollGroup && $('.js-item-group.active').length) $('.js-item-groups').scrollLeft(0).animate({ scrollLeft: $('.js-item-group.active').position().left }, 600)
    if (scrollVariety && $('.js-item-variety.active').length) $('.js-item-varieties').scrollLeft(0).animate({ scrollLeft: $('.js-item-variety.active').position().left }, 600)
    if (scrollColor && $('.js-item-color.active').length) $('.js-item-colors').scrollLeft(0).animate({ scrollLeft: $('.js-item-color.active').position().left }, 600)

    $collectItems.children().hide()
    const fetch = `product_${activeSex}_${activeBrandId}_${activeGroupId}_${activeVarietyId}_${activeColorId}_${activeProductName}`
    const $activeAppended = $collectItems.children(`[data-fetch-index="${fetches.indexOf(fetch)}"]`)
    $activeAppended.fadeIn(400)

    window.App.checkItems()

    if ($activeAppended.length) return
    const newFetchIndex = fetches.length
    fetches.push(fetch)
    $.ajax({
      type: 'GET',
      url: '/paging/items/products',
      dataType: 'script',
      data: {
        page: 0,
        fetch_index: newFetchIndex,
        sex: activeSex,
        brand_id: activeBrandId,
        group_id: activeGroupId,
        variety_id: activeVarietyId,
        color_id: activeColorId,
        product_name: activeProductName,
        site_id: siteId,
      },
    }).done(function() {
      window.App.checkItems()
    })
    $('.js-infinite-spinner').show()
  }

  // item-sex
  $('.js-item-sex').on('click', function() {
    const newSex = $(this).data('sex')
    if (newSex === activeSex) return
    activeSex = newSex
    updateView()
  })

  // item-brand
  $('.js-item-brand').on('click', function() {
    const newBrandId = $(this).data('brand-id')
    if (newBrandId === activeBrandId) return
    activeBrandId = newBrandId
    updateView()
  })

  // item-group
  $('.js-item-group').on('click', function() {
    const newGroupId = $(this).data('group-id')
    if (newGroupId === activeGroupId) return
    activeGroupId = newGroupId
    activeVarietyId = 0
    updateView(false, false, true, false)
  })

  // item-variety
  $('.js-item-variety').on('click', function() {
    const newVarietyId = $(this).data('variety-id')
    if (newVarietyId === activeVarietyId) return
    activeVarietyId = newVarietyId
    updateView()
  })

  // item-color
  $('.js-item-color').on('click', function() {
    const newColorId = $(this).data('color-id')
    if (newColorId === activeColorId) return
    activeColorId = newColorId
    updateView()
  })

  // brand-search
  const $brandSearchModal = $('.js-brand-search-modal')
  $(document).on('click', '.js-item-brand-search', function() {
    $brandSearchModal.modal('show')
  })

  $(document).on('click', '.js-brand-search-modal-close-button', function() {
    $brandSearchModal.modal('hide')
  })

  $(document).on('click', '.js-brand-initial', function() {
    $('.js-brand-initial').removeClass('active')
    $(this).addClass('active')
    const container = $('.brand-list__brand-list')
    const containerPosition = container.offset().top
    const initialGroup = $(`.js-brand-initial-group[data-brand-initial="${$(this).data('brand-initial')}"]`)
    const brandPosition = initialGroup.offset().top
    container.animate({
      scrollTop: container.scrollTop() + (brandPosition - containerPosition),
    })
  })

  $(document).on('input', '.js-brand-search-input', function() {
    const brandInitialList = []
    const pattern = new RegExp($(this).val().trim(), 'i')

    $('.js-brand-search-item').each(function(i, item) {
      const $item = $(item)
      if(pattern.test($item.data('name')) || pattern.test($item.data('kanaName'))) {
        $item.show()
        brandInitialList.push($item.data('brandInitial'))
      } else {
        $item.hide()
      }
    })
    $('.js-brand-initial-group').each(function(i, item) {
      const $item = $(item)
      if(brandInitialList.includes($item.data('brandInitial'))) {
        $item.show()
      } else {
        $item.hide()
      }
    })
    $('.js-brand-initial').each(function(i, item) {
      const $item = $(item)
      if(brandInitialList.includes($item.data('brandInitial'))) {
        $item.parent().show()
      } else {
        $item.parent().hide()
      }
    })
  })

  $(document).on('click', '.js-brand-search-item', function() {
    const newBrandId = $(this).data('brand-id')
    activeBrandId = newBrandId
    $brandSearchModal.modal('hide')
    updateView(true)
  })

  // product-name
  const $productNameModal = $('.js-product-name-modal')
  const $itemProductNameInput = $('.js-item-product-name-input')
  const updateProductName = function(productName) {
    $productNameModal.modal('hide')
    activeProductName = productName
    $itemProductNameInput.val(productName)
    updateView()
  }

  const productNameLocalStoragekey = 'proposal_product_name_search_history'
  const getProductNameHistory = function() {
    const json = localStorage.getItem(productNameLocalStoragekey)
    return json ? JSON.parse(json) : []
  }

  const addProductNameHistory = function(productName) {
    const histories = getProductNameHistory()
    const index = histories.indexOf(productName)
    if (index >= 0) {
      histories.splice(index, 1)
    }
    histories.unshift(productName)
    localStorage.setItem(productNameLocalStoragekey, JSON.stringify(histories))
  }

  const removeProductNameHistory = function(productName) {
    const histories = getProductNameHistory()
    const index = histories.indexOf(productName)
    if (index >= 0) {
      histories.splice(index, 1)
    }
    localStorage.setItem(productNameLocalStoragekey, JSON.stringify(histories))
  }

  const $productNameModalSearchHistories = $('.js-product-name-modal-search-histories')
  const $productNameModalSearchHistory = $('.js-product-name-modal-search-history')
  const showSearchHistories = function() {
    const histories = getProductNameHistory()
    $productNameModalSearchHistories.empty()
    histories.forEach(function(productName) {
      const $searchHistory = $productNameModalSearchHistory.clone(true)
      $searchHistory.removeClass('d-none')
      $searchHistory.find('.js-product-name-modal-search-history-product-name').text(productName)
      $productNameModalSearchHistories.append($searchHistory)
    })
  }

  $(document).on('click', '.js-product-name-modal-search-history-box', function() {
    const productName = $(this).find('.js-product-name-modal-search-history-product-name').text()
    updateProductName(productName)
    addProductNameHistory(productName)
  })

  const $productNameModalInput = $('.js-product-name-modal-input')
  $(document).on('click', '.js-item-product-name-btn', function() {
    $productNameModalInput.val(activeProductName)
    showSearchHistories()
    $productNameModal.modal('show')
  })

  $(document).on('click', '.js-product-name-modal-remove-search-history', function() {
    const $searchHistory = $(this).closest('.js-product-name-modal-search-history')
    const productName = $searchHistory.find('.js-product-name-modal-search-history-product-name').text()
    removeProductNameHistory(productName)
    $searchHistory.remove()
  })

  let isComposing = false
  $productNameModalInput.on('compositionstart', function() {
    isComposing = true
  })
  $productNameModalInput.on('compositionend', function() {
    // safariでは compositionend の後に keydown が発火してしまうため遅延させる
    window.setTimeout(function() {
      isComposing = false
    }, 100)
  })
  $productNameModalInput.on('keydown', function(e) {
    if (e.key === 'Enter') {
      if (!isComposing) {
        const productName = $productNameModalInput.val().trim()
        if (productName) {
          updateProductName(productName)
          addProductNameHistory(productName)
          $productNameModalInput.blur()
        }
      }
      return false
    }
  })

  $(document).on('click', '.js-product-name-modal-cancel-btn', function() {
    $productNameModalInput.val('')
  })

  $(document).on('click', '.js-product-name-modal-close-btn', function() {
    $productNameModal.modal('hide')
  })

  $(document).on('click', '.js-product-name-modal-clear-btn', function() {
    updateProductName('')
  })

  function getProduct(productId) {
    $.ajax({
      method: 'GET',
      url: ('/items/products/' + productId),
      dataType: 'script',
    }).done(function() {
      canvas.getObjects().filter(obj => obj.productId === productId).forEach(obj => {
        $(`.js-collect-product-photo[data-product-id="${obj.productId}"][data-id="${obj.photoId}"] .js-collect-item-check`).attr('data-check', '1')
      })
    })
    $('.js-spinner-modal').modal('show')
  }

  const $checkProductModal = $('.js-check-product-modal')
  // collect-product-col
  let currentProductId = null
  $(document).on('click', '.js-collect-product-col', function() {
    const productId = $(this).data('id')
    if (currentProductId === productId) {
      $checkProductModal.modal('show')
    } else {
      getProduct(productId)
      currentProductId = productId
    }
  })

  const $collagePaletteBody = $('.js-collage-palette-body')
  const $collagePaletteMenuIcon = $('.js-collage-palette-menu-icon')
  function toggleCollageItems() {
    $collagePaletteBody.slideToggle()
    $collagePaletteMenuIcon.toggleClass('down')
  }
  $collagePaletteMenuIcon.on('click', function() {
    toggleCollageItems()
  })

  const $collagePaletteMenuTab = $('.js-collage-palette-menu-tab')
  const $collagePaletteMain = $('.js-collage-palette-main')
  $collagePaletteMenuTab.on('click', function() {
    $collagePaletteMenuTab.removeClass('active')
    $(this).addClass('active')
    $collagePaletteMain.hide()
    $collagePaletteMain.eq($collagePaletteMenuTab.index(this)).show()
    if ($collagePaletteMenuIcon.hasClass('down')) toggleCollageItems()
  })

  $('.js-collage-clone-btn').on('click', function() {
    const activeObj = canvas.getActiveObject()
    if (activeObj) {
      activeObj.clone(function(cloned) {
        cloned.setControlsVisibility({
          mtr: true,
          mt: false,
          tr: false,
          mr: false,
          br: true,
          mb: false,
          bl: false,
          ml: false,
          tl: false,
        })
        cloned.set('photoId', activeObj.photoId)
        cloned.set('productId', activeObj.productId)
        cloned.set('prefixedId', activeObj.prefixedId)
        cloned.set('borderColor', 'rgb(48,176,255)')
        cloned.set('cornerColor', 'rgb(48,176,255)')
        cloned.set('cornerStyle', 'circle')
        cloned.set('cornerSize', 24)
        cloned.set('transparentCorners', false)
        cloned.left += 20
        cloned.top += 20
        canvas.add(cloned)
        canvas.setActiveObject(cloned)
      })
    }
  })
  $('.js-collage-remove-btn').on('click', function() {
    const activeObj = canvas.getActiveObject()
    if (activeObj) {
      canvas.remove(activeObj)
      if (!canvas.getObjects().some(obj => obj.productId === activeObj.productId)) {
        $(`.js-collect-product-col[data-id="${activeObj.productId}"] .js-collect-item-check`).attr('data-check', '0')
      }
      if (!canvas.getObjects().some(obj => obj.prefixedId === activeObj.prefixedId)) {
        $(`.js-collect-product-photo[data-product-id="${activeObj.productId}"][data-id="${activeObj.photoId}"] .js-collect-item-check`).attr('data-check', '0')
      }
    }
  })
  $('.js-collage-up-btn').on('click', function() {
    const activeObj = canvas.getActiveObject()
    if (activeObj) {
      activeObj.bringToFront()
    }
  })
  $('.js-collage-down-btn').on('click', function() {
    const activeObj = canvas.getActiveObject()
    if (activeObj) {
      activeObj.sendToBack()
    }
  })

  const $textModal = $('.js-text-modal'),
    $textModalBody = $('.js-text-modal-body')
  function showFailedCollagePhotoText() {
    $textModalBody.html('<p>画像の取得に失敗しました。時間をおいて再度お試しください。</p>')
    $textModal.modal('show')
  }

  function addSelectedItem(photoId, productId, prefixedId) {
    if (canvas.getObjects().some(obj => obj.prefixedId === prefixedId)) {
      canvas.setActiveObject(canvas.getObjects().reverse().find(obj => obj.prefixedId === prefixedId))
    } else {
      $.ajax({
        method: 'GET',
        url: '/api/collage_photo',
        dataType: 'json',
        data: {
          id: prefixedId,
        },
      }).done(function(data) {
        if (data.collage_photo_url) {
          fabric.Image.fromURL(data.collage_photo_url, function(img) {
            img.scaleToWidth(150)
            img.setControlsVisibility({
              mtr: true,
              mt: false,
              tr: false,
              mr: false,
              br: true,
              mb: false,
              bl: false,
              ml: false,
              tl: false,
            })
            img.set('photoId', photoId)
            img.set('productId', productId)
            img.set('prefixedId', prefixedId)
            img.set('borderColor', 'rgb(48,176,255)')
            img.set('cornerColor', 'rgb(48,176,255)')
            img.set('cornerStyle', 'circle')
            img.set('cornerSize', 24)
            img.set('transparentCorners', false)
            img.set('top', 100)
            img.set('left', 75)
            canvas.add(img)
            canvas.setActiveObject(img)
          },
          {
            crossOrigin: 'anonymous',
          })
        } else {
          showFailedCollagePhotoText()
        }
      }).fail(function() {
        showFailedCollagePhotoText()
      }).always(function() {
        $('.js-collage-spinner-modal').modal('hide')
      })
      $('.js-collage-spinner-modal').modal('show')
    }
  }

  // item-check
  $(document).on('click', '.js-collect-product-photo', function() {
    if (window.gon.maxItemCount <= canvas.getObjects().map(obj => obj.prefixedId).filter((v, i, a) => a.indexOf(v) === i).length) {
      $checkProductModal.modal('hide')
      toggleCollageItems()
      $textModalBody.html(`<p>画像は${window.gon.maxItemCount}種類まで選択できます</p>`)
      $textModal.modal('show')
      return
    }
    const $this = $(this)
    const photoId = $this.data('id')
    const productId = $this.data('product-id')
    if (photoId === 0) {
      addSelectedItem(photoId, productId, `product${productId}`)
    } else {
      addSelectedItem(photoId, productId, `productPhoto${photoId}`)
    }
    $(`.js-collect-product-col[data-id="${productId}"] .js-collect-item-check`).attr('data-check', '1')
    $(`.js-collect-product-photo[data-id="${photoId}"] .js-collect-item-check`).attr('data-check', '1')
    $this.find('.js-collect-item-check').attr('data-check', '1')
    $checkProductModal.modal('hide')
    toggleCollageItems()
  })

  let activeBackgroundId = null
  $(document).on('click', '.js-background-image', function() {
    const $this = $(this)
    const backgroundId = $this.data('id')
    if (activeBackgroundId === backgroundId) {
      canvas.setBackgroundImage(null, canvas.renderAll.bind(canvas))
      activeBackgroundId = null
    } else {
      canvas.setBackgroundImage($this.data('image-url'), canvas.renderAll.bind(canvas), {
        width: canvas.width,
        height: canvas.height,
        originX: 'left',
        originY: 'top',
        crossOrigin: 'anonymous',
      })
      activeBackgroundId = backgroundId
    }
    toggleCollageItems()
  })

  $('.js-collage-new-btn').on('click', function() {
    if (canvas.getObjects().length < 1) {
      $textModalBody.html('<p>アイテムは1つ以上選択してください</p>')
      $textModal.modal('show')
    } else {
      sessionStorage.setItem(collageImageKey, canvas.toDataURL({
        format: 'png',
        multiplier: 4,
      }))
      sessionStorage.setItem(collagePrefixedIdsKey, JSON.stringify(
        canvas.getObjects().map(obj => obj.prefixedId).filter((v, i, a) => a.indexOf(v) === i)
      ))
      const url = new URL(`${window.location.protocol}//${window.location.host}/collages/new`)
      url.searchParams.append('site_id', siteId)
      const prefixedIds = JSON.parse(sessionStorage.getItem(collagePrefixedIdsKey))
      if (prefixedIds) {
        prefixedIds.forEach(function(prefixedId) {
          url.searchParams.append('ids[]', prefixedId)
        })
      }
      window.location.href = url
      $('.js-spinner-modal').modal('show')
    }
  })

  // init
  updateView(true, true, true, true)
  const ctx = canvas.getContext('2d')
  const img = new Image
  img.src = sessionStorage.getItem(collageImageKey)
  ctx.drawImage(img, 0, 0)

  $.ajax({
    type: 'GET',
    url: '/paging/backgrounds',
    dataType: 'script',
    data: {
      page: 0,
      site_id: siteId,
    },
  })
})
