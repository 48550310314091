$(function() {
  if ($('body.all-collections.index').length < 1) return

  const siteId = $('.js-home-sites').data('site-id')

  let loadPage = 0
  function setCollectionInfiniteLoader() {
    const intervalID = window.setInterval(function() {
      if ($('.js-list-collection:eq(-2)').is(':appeared')) {
        window.clearInterval(intervalID)
        $('.js-infinite-spinner').show()
        getCollections(loadPage++)
      }
    }, 2000)
  }

  function getCollections(page) {
    $.ajax({
      type: 'GET',
      url: '/paging/collections',
      dataType: 'script',
      data: {
        page: page,
        site_id: siteId,
      },
    }).done(function() {
      setCollectionInfiniteLoader()
    }).always(function() {
      $('.js-infinite-spinner').hide()
    })
  }

  $(document).on('click', '.js-collection-btn', function() {
    $('.js-edit-collection-btn').attr('href', $(this).data('edit-path'))
    $('.js-collection-edit-modal').modal('show')
  })

  // init
  $(`.js-home-site[data-site-id="${siteId}"]`).addClass('active')
  $('.js-infinite-spinner').show()
  getCollections(loadPage++)
})
