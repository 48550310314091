$(function() {
  const $bottomFooter = $('.js-bottom-footer')
  if ($bottomFooter.length) {
    const activeIndex = $bottomFooter.data('active-index')
    if (activeIndex !== undefined) $bottomFooter.children('a').eq(activeIndex).addClass('active')
  }

  // bottom-menu-modal
  const $bottomMenuModal = $('.js-bottom-menu-modal')
  const $bottomFooterBtn = $('.js-bottom-footer-btn')
  $('.js-bottom-footer-btn').on('click', function() {
    $bottomMenuModal.modal('toggle')
  })
  $bottomMenuModal.on('show.bs.modal', function() {
    $bottomFooter.addClass('menu-modal')
    $bottomFooterBtn.addClass('menu-modal')
  })
  $bottomMenuModal.on('hide.bs.modal', function() {
    $bottomFooterBtn.removeClass('menu-modal')
    $bottomFooter.removeClass('menu-modal')
  })

  // bottom-footer-link-count
  const notificationIntervalMs = 60 * 1000 * 5,
    $bottomFooterLinkCount = $('.js-bottom-footer-link-count')
  if ($('body').hasClass('js-signed-in') && $bottomFooterLinkCount.length) {
    if ($('body.all-notifications.index').length) {
      sessionStorage.setItem('notificationCount', 0)
      sessionStorage.setItem('fetchedNotificationDate', new Date())
    } else {
      const dateStr = sessionStorage.getItem('fetchedNotificationDate'),
        fetchedNotificationDate = dateStr ? new Date(dateStr) : null
      if (fetchedNotificationDate && (new Date() - fetchedNotificationDate) < notificationIntervalMs) {
        const notificationCount = sessionStorage.getItem('notificationCount')
        if (notificationCount) showNotificationCount(Number(notificationCount))
      } else {
        $.ajax({
          method: 'GET',
          url: '/api/unread/notifications',
          dataType: 'json',
        }).done(function(data) {
          showNotificationCount(data.notificationCount)
          sessionStorage.setItem('notificationCount', data.notificationCount)
          sessionStorage.setItem('fetchedNotificationDate', new Date())
        }).fail(function() {})
      }
    }
  }
  function showNotificationCount(count) {
    if (count < 1) return
    $bottomFooterLinkCount.text(count)
  }

  // collage-site
  $('.js-collage-site').on('click', function(e) {
    e.preventDefault()
    $('.js-collage-site-modal').modal('show')
  })

  // collection-site
  $('.js-collection-site').on('click', function(e) {
    e.preventDefault()
    $('.js-collection-site-modal').modal('show')
  })

  // style-site
  $('.js-style-site').on('click', function(e) {
    e.preventDefault()
    $('.js-style-site-modal').modal('show')
  })
  $('.js-open-style-guide').on('click', function() {
    $('.js-style-guide-modal').modal('show')
  })

  // share-site
  $('.js-share-site').on('click', function(e) {
    e.preventDefault()
    $('.js-share-site-modal').modal('show')
  })
  $('.js-open-share-guide').on('click', function() {
    $('.js-share-guide-modal').modal('show')
  })
})
