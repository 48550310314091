$(function() {
  if ($('body.users-followees.index').length < 1) return

  const $followeeSearchInput = $('.js-followee-search-input'),
    hasOverUserFollowsLimit = $followeeSearchInput.hasClass('js-over-user-follows-limit')
  let fetchedPaging = false
  $followeeSearchInput.on('input', function() {
    const val = $(this).val()
    if (val) {
      $('.js-followee-user').each(function() {
        const $followeeUser = $(this)
        if ($followeeUser.data('username').includes(val) || $followeeUser.data('nickname').includes(val)) {
          $followeeUser.fadeIn(400)
        } else {
          $followeeUser.hide()
        }
      })
    } else {
      $('.js-followee-user').each(function() {
        $(this).fadeIn(400)
      })
    }
  })
  // paging
  if (hasOverUserFollowsLimit) {
    $followeeSearchInput.one('focus', function() {
      if (fetchedPaging) return
      fetchPagingUserFollows()
    })
    const followeeUserIntervalId = window.setInterval(function() {
      if ($('.js-followee-user:eq(-2)').is(':appeared')) {
        window.clearInterval(followeeUserIntervalId)
        if (fetchedPaging) return
        $('.js-infinite-spinner').show()
        fetchPagingUserFollows()
      }
    }, 2000)
  }
  function fetchPagingUserFollows() {
    $.ajax({
      type: 'GET',
      url: '/paging/users/followees',
      dataType: 'script',
    })
    fetchedPaging = true
  }
})
