$(function() {
  if ($('body.asks-proposals.new, body.asks-proposals.edit').length < 1) return

  let prefixedIds = [],
    productIdPhotoId = {},
    selectedItemList = []
  function resetProposalItemData() {
    prefixedIds = []
    productIdPhotoId = {}
    selectedItemList = []
    $('.js-proposal-item-col').each(function() {
      const $this = $(this),
        prefixedId = $this.data('prefixed-id'),
        productId = $this.data('product-id')
      prefixedIds.push(prefixedId)
      if (productId) {
        productIdPhotoId[productId] = $this.data('id') || 0
      }
      selectedItemList.push({ prefixedId: prefixedId, src: $this.find('.js-proposal-item-img').attr('src') })
    })
    sessionStorage.setItem('prefixedIds', JSON.stringify(prefixedIds))
    sessionStorage.setItem('productIdPhotoId', JSON.stringify(productIdPhotoId))
    sessionStorage.setItem('selectedItemList', JSON.stringify(selectedItemList))
  }

  function resetProposalItems() {
    let styleIdx = 0,
      productPhotoIdx = 0,
      productIdx = 0
    $('.js-proposal-item-col').each(function(i) {
      const $this = $(this)
      $this.find('.js-item-number').text(i + 1)
      if ($this.data('prefixed-id').startsWith('style')) {
        $this.find('.js-input-style-id').attr('name', `proposal[proposal_styles_attributes][${styleIdx}][style_id]`)
        $this.find('.js-input-style-order').attr('name', `proposal[proposal_styles_attributes][${styleIdx}][display_order]`)
        $this.find('.js-input-style-order').attr('value', i + 1)
        styleIdx++
      } else if ($this.data('prefixed-id').startsWith('productPhoto')) {
        $this.find('.js-input-product-photo-id').attr('name', `proposal[proposal_product_photos_attributes][${productPhotoIdx}][product_photo_id]`)
        $this.find('.js-input-product-photo-order').attr('name', `proposal[proposal_product_photos_attributes][${productPhotoIdx}][display_order]`)
        $this.find('.js-input-product-photo-order').attr('value', i + 1)
        productPhotoIdx++
      } else {
        $this.find('.js-input-product-id').attr('name', `proposal[proposal_products_attributes][${productIdx}][product_id]`)
        $this.find('.js-input-product-order').attr('name', `proposal[proposal_products_attributes][${productIdx}][display_order]`)
        $this.find('.js-input-product-order').attr('value', i + 1)
        productIdx++
      }
    })
  }

  const $proposalCaption = $('.js-proposal-caption'),
    maxCaptionLength = 800
  // letter-count
  const $captionLetterCount = $('.js-caption-letter-count')
  function updateCount() {
    const count = $proposalCaption.val().length
    $captionLetterCount.text(count > maxCaptionLength ? maxCaptionLength - count : count)
    if (count > maxCaptionLength) {
      $captionLetterCount.addClass('over')
    } else {
      $captionLetterCount.removeClass('over')
    }
  }
  $proposalCaption.on('input', function() {
    updateCount()
    sessionStorage.setItem('proposalCaption', $proposalCaption.val())
  })

  // init
  resetProposalItemData()
  resetProposalItems()
  if (sessionStorage.getItem('proposalCaption')) $proposalCaption.val(sessionStorage.getItem('proposalCaption'))
  updateCount()

  const $textModal = $('.js-text-modal'),
    $textModalBody = $('.js-text-modal-body')
  // item-remove-div
  const invalidItemsTextP = '<p>アイテムは1件以上必須のため削除できません</p>'
  $('.js-item-remove-div').on('click', function() {
    if (1 < $('.js-proposal-item-col').length) {
      const $proposalItemCol = $(this).closest('.js-proposal-item-col'),
        prefixedId = $proposalItemCol.data('prefixed-id'),
        productId = $proposalItemCol.data('product-id')
      prefixedIds = prefixedIds.filter(id => id !== prefixedId)
      sessionStorage.setItem('prefixedIds', JSON.stringify(prefixedIds))
      if (productId) {
        delete productIdPhotoId[productId]
        sessionStorage.setItem('productIdPhotoId', JSON.stringify(productIdPhotoId))
      }
      selectedItemList = selectedItemList.filter(selectedItem => selectedItem.prefixedId !== prefixedId)
      sessionStorage.setItem('selectedItemList', JSON.stringify(selectedItemList))
      $proposalItemCol.remove()
      resetProposalItems()
    } else {
      $textModalBody.html(invalidItemsTextP)
      $textModal.modal('show')
    }
  })

  // sort
  new Sortable(document.querySelector('.js-proposal-items'), {
    animation: 150,
    handle: '.js-proposal-item-handle',
    onUpdate: function() {
      resetProposalItemData()
      resetProposalItems()
    },
  })

  // submit
  const invalidCaptionTextP = '<p>キャプションを入力してください</p>',
    minCaptionLength = 80,
    smallCaptionLength = 150,
    $confirmModal = $('.js-confirm-modal'),
    $confirmModalBody = $('.js-confirm-modal-body'),
    confirmDisplayTextP = '<p>非公開として登録されます。<br>よろしいですか？</p>',
    confirmCaptionTextP = `<p>${smallCaptionLength}字以下の提案をすると<br>オススメ表示されにくくなります。<br>このまま登録しますか？</p>`,
    underLengthCaptionTextP =
      `<p class="text-modal-body__title">キャプションは${minCaptionLength}字以上です</p><p class="text-modal-body__note">${smallCaptionLength}字以上の提案でオススメスタイリストとして<br>表示されやすくなります！</p>`,
    overLengthCaptionTextP = `<p>キャプションは${maxCaptionLength}字以内です</p>`
  $('.js-create-proposal-btn').on('click', function() {
    if (!$proposalCaption.val().trim()) {
      $textModalBody.html(invalidCaptionTextP)
      $textModal.modal('show')
    } else if ($('.js-proposal-is-display').length && !$('.js-proposal-is-display').prop('checked')) {
      $confirmModalBody.html(confirmDisplayTextP)
      $confirmModal.modal('show')
    } else if ($proposalCaption.val().length < minCaptionLength) {
      $textModalBody.html(underLengthCaptionTextP)
      $textModal.modal('show')
    } else if ($proposalCaption.val().length <= smallCaptionLength) {
      $confirmModalBody.html(confirmCaptionTextP)
      $confirmModal.modal('show')
    } else if (maxCaptionLength < $proposalCaption.val().length) {
      $textModalBody.html(overLengthCaptionTextP)
      $textModal.modal('show')
    } else {
      submit()
    }
  })
  $('.js-confirm-modal-btn').on('click', function() {
    $confirmModal.modal('hide')
    submit()
  })
  function submit() {
    Rails.fire(document.querySelector('.js-proposal-form'), 'submit')
    $('.js-spinner-modal').modal('show')
  }
})
