$(function() {
  if ($('body.all-collages.index').length < 1) return

  const siteId = $('.js-home-sites').data('site-id')

  let loadPage = 0
  function setCollageInfiniteLoader() {
    const intervalID = window.setInterval(function() {
      if ($('.js-collage-col:eq(-2)').is(':appeared')) {
        window.clearInterval(intervalID)
        $('.js-infinite-spinner').show()
        getCollages(loadPage++)
      }
    }, 2000)
  }

  function getCollages(page) {
    $.ajax({
      type: 'GET',
      url: '/paging/collages',
      dataType: 'script',
      data: {
        page: page,
        site_id: siteId,
      },
    }).done(function() {
      setCollageInfiniteLoader()
    }).always(function() {
      $('.js-infinite-spinner').hide()
    })
  }

  // init
  $(`.js-home-site[data-site-id="${siteId}"]`).addClass('active')
  $('.js-infinite-spinner').show()
  getCollages(loadPage++)
})
