$(function() {
  if ($('body.users-shipping_addresses.index').length < 1) return

  // choose
  $('.js-choose-shipping-address-btn').on('click', function() {
    $.ajax({
      method: 'PATCH',
      url: (location.pathname + '/' + $('input[name="way"]:checked').val() + '/choose'),
      dataType: 'script',
    })
    $('.js-spinner-modal').modal('show')
  })
})
