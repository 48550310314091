$(function() {
  if ($('body.sites-coins.index').length < 1) return

  $('.js-coin-level-icon').on('click', function() {
    $('.js-coin-level-modal').modal('show')
  })
  $('.js-approved-coin-icon').on('click', function() {
    $('.js-approved-coin-modal').modal('show')
  })
  $('.js-serving-cv-icon').on('click', function() {
    $('.js-serving-cv-modal').modal('show')
  })
  $('.js-sending-cv-icon').on('click', function() {
    $('.js-sending-cv-modal').modal('show')
  })
})
