$(function() {
  if ($('body.collections.show').length < 1) return

  // masonry
  window.App.$grid = $('.grid').imagesLoaded(function() {
    window.App.$grid.masonry({
      itemSelector: '.grid-item',
      columnWidth: '.grid-sizer',
      percentPosition: true,
    })
  })

  // product-view
  const { apiGatewayUrl, apiGatewayApiKey, siteId, userId, collectionId, productCodes } = window.gon
  App.util.sendProductView({
    apiGatewayUrl, apiGatewayApiKey, siteId, userId, productCodes, collectionId,
  })
})
