$(function() {
  if ($('body.proposals.show').length < 1) return

  // masonry
  window.App.$grid = $('.grid').imagesLoaded(function() {
    window.App.$grid.masonry({
      itemSelector: '.grid-item',
      columnWidth: '.grid-sizer',
      percentPosition: true,
    })
  })

  const $inputCommentModal = $('.js-input-comment-modal'),
    $inputCommentModalComment = $inputCommentModal.find('.js-input-comment-modal-comment')
  // input-comment-modal
  $('.js-select-proposal-btn').on('click', function() {
    $inputCommentModal.modal('show')
  })

  const $bestProposalCommentLetterCount = $('.js-input-comment-modal-comment-letter-count')
  function updateCount() {
    const count = $inputCommentModalComment.val().trim().length
    $bestProposalCommentLetterCount.text(count)
  }
  $inputCommentModalComment.on('input', function() {
    updateCount()
  })

  // confirm-comment-modal
  const $confirmCommentModal = $('.js-confirm-comment-modal'),
    $confirmCommentModalComment = $confirmCommentModal.find('.js-confirm-comment-modal-comment'),
    $textModal = $('.js-text-modal'),
    $textModalBody = $('.js-text-modal-body'),
    invalidTextEmptyP = '<p>コメントを入力してください</p>',
    invalidTextLengthDiv =
      '<p class="text-modal-body__title">30文字以上の入力をお願いします</p><p class="text-modal-body__message">ご提案ありがとうございました。<br>〇〇のアイテムがとても素敵で<br>参考になりました！</p>'
  $('.js-input-comment-modal-btn').on('click', function() {
    const comment = $inputCommentModalComment.val().trim()
    if (!comment) {
      $textModalBody.html(invalidTextEmptyP)
      $textModal.modal('show')
    } else if(comment.length < 30) {
      $textModalBody.html(invalidTextLengthDiv)
      $textModal.modal('show')
    } else {
      $inputCommentModal.modal('hide')
      $confirmCommentModalComment.text($inputCommentModalComment.val())
      $confirmCommentModal.modal('show')
    }
  })
  $('.js-confirm-comment-modal-btn').on('click', function() {
    $.ajax({
      method: 'PATCH',
      url: location.pathname + '/select',
      dataType: 'script',
      data: {
        ask_best_proposal: {
          comment: $inputCommentModalComment.val(),
        },
      },
    })
    $confirmCommentModal.modal('hide')
    $('.js-spinner-modal').modal('show')
  })

  // messages
  const $textareaFooterModal = $('.js-textarea-footer-modal'),
    $textareaFooterModalImage = $textareaFooterModal.find('.js-textarea-footer-modal-image')
  $('.js-textarea-footer-send').on('click', function() {
    $('.js-spinner-modal').modal('show')
    $('.js-textarea-footer-textarea').prop('disabled', false)
    $('#js-textarea-footer-file').prop('disabled', true)
    Rails.fire(document.querySelector('.js-textarea-footer-form'), 'submit')
  })
  $('#js-textarea-footer-file').on('change', function(event) {
    const file = event.target.files[0]
    if (!file) return
    const reader = new FileReader()
    reader.onload = function() {
      $textareaFooterModalImage.attr('src', reader.result)
      $textareaFooterModal.modal('show')
    }
    reader.readAsDataURL(file)
  })
  $('#js-textarea-footer-file').on('click', function() {
    $(this).val('')
  })
  $('.js-textarea-footer-modal-submit').on('click', function() {
    $('.js-spinner-modal').modal('show')
    $textareaFooterModal.modal('hide')
    $('.js-textarea-footer-textarea').prop('disabled', true)
    $('#js-textarea-footer-file').prop('disabled', false)
    Rails.fire(document.querySelector('.js-textarea-footer-form'), 'submit')
  })

  // 相談アイテム非表示
  $('.js-close-ask-photo-btn').on('click', function() {
    $('.js-proposal-ask-photo').fadeOut(500)
  })

  // product-view
  const { apiGatewayUrl, apiGatewayApiKey, siteId, userId, proposalId, askProductCode, productCodes } = window.gon
  App.util.sendProductView({
    apiGatewayUrl, apiGatewayApiKey, siteId, userId, productCodes, proposalId, askProductCode,
  })
})
