$(function() {
  const deleteConfirmModal = $('.js-ask-delete-modal')

  $(document).on('click', '.js-ask-delete-modal-btn', function() {
    deleteConfirmModal.modal('show')
  })

  $(document).on('click', '.js-ask-delete-btn', function() {
    const $this = $(this)
    $.ajax({
      method: 'DELETE',
      url: $this.data('path'),
    }).done(function() {
      window.location.href = $this.data('return-path')
    }).fail(function() {
      window.alert(window.App.messages.errorAndTry)
      deleteConfirmModal.modal('hide')
    })
  })
})
