$(function() {
  if ($('body.collages.confirm').length < 1) return

  const siteId = window.gon.siteId
  const collageImageKey = `collage_site${siteId}_collageImage`
  const collageNameKey = `collage_site${siteId}_collageName`
  const collageCaptionKey = `collage_site${siteId}_collageCaption`
  const inputTags = JSON.parse(sessionStorage.getItem('inputTags'))

  $('.js-create-collage-btn').on('click', function() {
    const collageDate = {
      site_id: siteId,
      image: sessionStorage.getItem(collageImageKey),
      name: sessionStorage.getItem(collageNameKey),
      caption: sessionStorage.getItem(collageCaptionKey),
    }
    const productPhotos = []
    const products = []
    $('.js-collage-item-col').each(function(i, item) {
      if (item.dataset.productPhotoId) {
        productPhotos.push({ product_photo_id: item.dataset.productPhotoId, display_order: i++ })
      } else if (item.dataset.productId) {
        products.push({ product_id: item.dataset.productId, display_order: i++ })
      }
    })
    if (productPhotos.length) collageDate['collage_product_photos_attributes'] = productPhotos
    if (products.length) collageDate['collage_products_attributes'] = products
    $.ajax({
      method: 'POST',
      url: '/collages',
      dataType: 'script',
      data: {
        collage: collageDate,
        tags: inputTags,
      },
    })
    $('.js-spinner-modal').modal('show')
  })

  // init
  $('.js-collage-image').attr('src', sessionStorage.getItem(collageImageKey))
  $('.js-collage-name').text(sessionStorage.getItem(collageNameKey))
  $('.js-collage-caption').text(sessionStorage.getItem(collageCaptionKey))
  const $tagCards = $('.js-tag-cards')
  if (inputTags) {
    inputTags.forEach(function(tag) {
      $tagCards.append(`
        <div class="tag-card">
          <p>
            <span>#</span>
            <span>${tag}</span>
          </p>
        </div>
      `)
    })
  }
})
