$(function() {
  if ($('body.stylists-transfers.new').length < 1) return

  const $confirmForm = $('.js-confirm-form')
  let remitAmount = 0
  // claim_amount
  const balance = $confirmForm.data('balance'),
    bankFee = 700,
    maxRemitAmount = balance <= bankFee ? 0 : (balance - bankFee),
    $transferClaimAmount = $('.js-transfer-claim-amount'),
    $transferRemitAmount = $('.js-transfer-remit-amount')
  $transferClaimAmount.on('input', function() {
    remitAmount = Number($transferClaimAmount.val()) - bankFee
    if (maxRemitAmount < remitAmount) {
      remitAmount = maxRemitAmount
      $transferClaimAmount.val(balance)
    }
    if (remitAmount < 0) remitAmount = 0
    $transferRemitAmount.text('¥' + remitAmount)
  })

  // submit
  const $textModalBody = $('.js-text-modal-body'),
    invalidTextP = '<p>振込金額が0円のため申請できません</p>',
    $textModal = $('.js-text-modal')
  $('.js-confirm-transfer-btn').on('click', function() {
    if (remitAmount) {
      $confirmForm.children('input[name="remit_amount"]').val(remitAmount)
      $confirmForm.submit()
      $('.js-spinner-modal').modal('show')
    } else {
      $textModalBody.html(invalidTextP)
      $textModal.modal('show')
    }
  })
})
