$(function() {
  if ($('body.users-shipping_addresses.new, body.users-shipping_addresses.edit').length < 1) return

  // submit
  const $textModal = $('.js-text-modal'),
    $textModalBody = $('.js-text-modal-body'),
    $spinnerModal = $('.js-spinner-modal')
  $('.js-upsert-shipping-address-btn').on('click', function() {
    const validationTextAry = []
    if (!$('#shipping_address_kanji_first_name').val().trim()) {
      validationTextAry.push('姓（全角）を入力してください')
    }
    if (!$('#shipping_address_kanji_last_name').val().trim()) {
      validationTextAry.push('名（全角）を入力してください')
    }
    if (!$('#shipping_address_kana_first_name').val().trim()) {
      validationTextAry.push('姓カナ（全角）を入力してください')
    }
    if (!$('#shipping_address_kana_last_name').val().trim()) {
      validationTextAry.push('名カナ（全角）を入力してください')
    }
    if (!$('#shipping_address_postal_code').val().trim()) {
      validationTextAry.push('郵便番号を入力してください')
    }
    if (!$('#shipping_address_prefecture').val().trim()) {
      validationTextAry.push('都道府県を入力してください')
    }
    if (!$('#shipping_address_district').val().trim()) {
      validationTextAry.push('市区町村を入力してください')
    }
    if (!$('#shipping_address_street').val().trim()) {
      validationTextAry.push('番地を入力してください')
    }
    if (!$('#shipping_address_phone_number').val().trim()) {
      validationTextAry.push('電話番号を入力してください')
    }

    if (validationTextAry.length) {
      $textModalBody.html(validationTextAry.map(function(text) {
        return `<p>${text}</p>`
      }).join(''))
      $textModal.modal('show')
    } else {
      Rails.fire(document.querySelector('.js-shipping-address-form'), 'submit')
      $spinnerModal.modal('show')
    }
  })
})
