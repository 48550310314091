$(function() {
  if ($('body.collages.new').length < 1) return

  const siteId = window.gon.siteId
  const collageImageKey = `collage_site${siteId}_collageImage`
  const collagePrefixedIdsKey = `collage_site${siteId}_collagePrefixedIds`
  const collageNameKey = `collage_site${siteId}_collageName`
  const collageCaptionKey = `collage_site${siteId}_collageCaption`
  const $collageName = $('.js-collage-name')
  const $collageCaption = $('.js-collage-caption')

  $collageName.on('input', function() {
    sessionStorage.setItem(collageNameKey, $collageName.val())
  })
  $collageCaption.on('input', function() {
    sessionStorage.setItem(collageCaptionKey, $collageCaption.val())
  })

  const $textModalBody = $('.js-text-modal-body')
  const $textModal = $('.js-text-modal')
  $('.js-collage-confirm-btn').on('click', function() {
    const collageTags = $('.js-tag-name').map(function() {
      return $(this).text()
    }).get()
    sessionStorage.setItem('inputTags', JSON.stringify(collageTags))
    if ($collageName.val().trim()) {
      const url = new URL(`${window.location.protocol}//${window.location.host}/collages/confirm`)
      url.searchParams.append('site_id', siteId)
      const prefixedIds = JSON.parse(sessionStorage.getItem(collagePrefixedIdsKey))
      if (prefixedIds) {
        prefixedIds.forEach(function(prefixedId) {
          url.searchParams.append('ids[]', prefixedId)
        })
      }
      window.location.href = url
      $('.js-spinner-modal').modal('show')
    } else {
      $textModalBody.html('<p>タイトルを入力してください</p>')
      $textModal.modal('show')
    }
  })

  // init
  $('.js-collage-image').attr('src', sessionStorage.getItem(collageImageKey))
  $collageName.val(sessionStorage.getItem(collageNameKey))
  $collageCaption.val(sessionStorage.getItem(collageCaptionKey))
})
