import './node_modules'

window.$ = $
window.Swiper = Swiper

window.App || (window.App = {})
import MESSAGES from 'packs/data/MESSAGES'
window.App.messages = MESSAGES
import Util from './Util'
window.App.util = Util

import './app'

import './shared/ask_modal'
import './shared/bottom_footer'
import './shared/copied_url_modal'
import './shared/follow'
import './shared/input_tags'
import './shared/items_collect'
import './shared/like'
import './shared/textarea_footer'
import './shared/to_top_button'

import './asks/delete'
import './asks/proposals_common'
import './asks_new'
import './asks_show'

import './all/asks_index'
import './all/collages_index'
import './all/collections_index'
import './all/contests_index'
import './all/notifications_index'
import './all/styles_index'
import './all/shares_index'

import './collages_collect'
import './collages_confirm'
import './collages_new'
import './collages_show'

import './collections_common'
import './collections_confirm'
import './collections_show'

import './contests_show'

import './infos_common'
import './infos_edit_identity'
import './infos_edit_profile'

import './pages_show'

import './products_show'

import './proposals_show'

import './shares_new'

import './sites/coins_index'
import './sites/privileges_index'

import './styles_common'
import './styles_confirm'
import './styles_show'

import './stylists/affiliate_rewards_index'
import './stylists/individual_rewards_index'
import './stylists/rewards_index'
import './stylists/transfers_confirm'
import './stylists/transfers_new'

import './users/bank_accounts_edit'
import './users/confirmations_new'
import './users/followees_index'
import './users/followers_index'
import './users/passwords_new'
import './users/passwords_edit'
import './users/registrations_new'
import './users/sessions_new'
import './users/shipping_addresses_common'
import './users/shipping_addresses_index'
import './users_asks'
import './users_collections'
import './users_proposals'
import './users_show'
import './users_styles'
import './users_collages'
