$(function() {
  if ($('body.users-bank_accounts.edit').length < 1) return

  const $bankNameModalBank = $('.js-bank-name-modal-bank')
  let currentBankName = null
  function selectBank(bankName) {
    $bankNameModalBank.each(function() {
      const $this = $(this),
        modalBankName = $this.find('span').text()
      if (bankName && bankName === modalBankName) {
        $this.find('i').show()
        currentBankName = modalBankName
      } else {
        $this.find('i').hide()
      }
    })
  }
  function deselectBanks() {
    $bankNameModalBank.each(function() {
      $(this).find('i').hide()
    })
  }

  const $bankNameInput = $('.js-bank-name-input'),
    initialBankName = $bankNameInput.val(),
    $bankNameDiv = $('.js-bank-name-div')
  // init
  if (initialBankName) {
    $bankNameDiv.hide()
  } else {
    $bankNameInput.hide()
  }
  selectBank(initialBankName)

  // bank_name
  const $bankNameModal = $('.js-bank-name-modal')
  $bankNameInput.on('click', function() {
    $bankNameModal.modal('show')
  })
  $bankNameDiv.on('click', function() {
    $bankNameModal.modal('show')
  })
  $bankNameModal.on('hide.bs.modal', function() {
    $bankNameInput.val(currentBankName)
    if (currentBankName) {
      $bankNameDiv.hide()
      $bankNameInput.show()
    } else {
      $bankNameInput.hide()
      $bankNameDiv.show()
    }
  })
  const $bankNameModalOtherBank = $('.js-bank-name-modal-other-bank')
  $bankNameModalBank.on('click', function() {
    $bankNameModalOtherBank.val('')
    selectBank($(this).find('span').text())
    $bankNameModal.modal('hide')
  })
  $bankNameModalOtherBank.on('blur', function() {
    const otherBankName = $(this).val().trim()
    if (otherBankName) {
      deselectBanks()
      currentBankName = otherBankName
    }
  })
  $('.js-bank-name-modal-btn').on('click', function() {
    $bankNameModal.modal('hide')
  })

  // submit
  const $confirmModal = $('.js-confirm-modal'),
    $confirmModalBankName = $('.js-confirm-modal-bank-name'),
    $confirmModalBranchCode = $('.js-confirm-modal-branch-code'),
    $confirmModalAccountType = $('.js-confirm-modal-account-type'),
    $confirmModalAccountNumber = $('.js-confirm-modal-account-number'),
    $confirmModalLastName = $('.js-confirm-modal-last-name'),
    $confirmModalFirstName = $('.js-confirm-modal-first-name')
  $('.js-update-bank-account-btn').on('click', function() {
    $confirmModalBankName.text($('#bank_account_bank_name').val())
    $confirmModalBranchCode.text($('#bank_account_branch_code').val())
    $confirmModalAccountType.text($('#bank_account_account_type option:selected').text())
    $confirmModalAccountNumber.text($('#bank_account_account_number').val())
    $confirmModalLastName.text($('#bank_account_last_name').val())
    $confirmModalFirstName.text($('#bank_account_first_name').val())
    $confirmModal.modal('show')
  })
  $('.js-confirm-modal-btn').on('click', function() {
    Rails.fire(document.querySelector('.js-bank-account-form'), 'submit')
    $confirmModal.modal('hide')
    $('.js-spinner-modal').modal('show')
  })
})
