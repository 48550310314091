$(function() {
  if ($('body.users-registrations.new').length < 1) return

  // inputs
  const $nickname = $('.js-nickname'),
    $sexLabel = $('.js-sex-label'),
    $birthYear = $('.js-birth-date-year'),
    $birthMonth = $('.js-birth-date-month'),
    $birthDay = $('.js-birth-date-day'),
    $height = $('.js-height'),
    $email = $('.js-email'),
    $password = $('.js-password')

  // sex
  $sexLabel.on('click', function(e) {
    e.preventDefault()
    $sexLabel.each(function() {
      const $this = $(this)
      if (this === e.target) {
        if (!$this.hasClass('active')) {
          $this.button('toggle')
          $this.children(':radio').prop('checked')
        }
      } else {
        if ($this.hasClass('active')) {
          $this.button('toggle')
          $this.children(':radio').prop('checked', false)
        }
      }
    })
  })

  // birth-date
  App.util.controlBirthDateInput()

  // create-btn
  const $textModal = $('.js-text-modal'),
    $textModalBody = $('.js-text-modal-body')
  $('.js-create-btn').on('click', function() {
    const validationTextAry = []
    if (!$nickname.val()) {
      validationTextAry.push('ニックネームを入力してください')
    }
    const sexVal = $sexLabel.filter('.active').children(':radio').val()
    if (!sexVal) {
      validationTextAry.push('性別を選択してください')
    }
    if (!$birthYear.val() || !$birthMonth.val() || !$birthDay.val()) {
      validationTextAry.push('生年月日を選択してください')
    }
    if (!$height.val()) {
      validationTextAry.push('身長を選択してください')
    }
    if (!$email.val()) {
      validationTextAry.push('メールアドレスを入力してください')
    } else if (!App.util.validEmail($email.val())) {
      validationTextAry.push('メールアドレスの形式が正しくありません')
    }
    if (!$password.val()) {
      validationTextAry.push('パスワードを入力してください')
    } else if ($password.val().length < 8) {
      validationTextAry.push('パスワードは8文字以上入力してください')
    }

    if (validationTextAry.length) {
      $textModalBody.html(validationTextAry.map(function(text) {
        return `<p>${text}</p>`
      }).join(''))
      $textModal.modal('show')
    } else {
      $.ajax({
        method: 'POST',
        url: '/users',
        dataType: 'script',
        data: {
          user: {
            nickname: $nickname.val(),
            sex: sexVal,
            birth_date: `${$birthYear.val()}${('0' + $birthMonth.val()).slice(-2)}${('0' + $birthDay.val()).slice(-2)}`,
            height: $height.val(),
            email: $email.val(),
            password: $password.val(),
          },
        },
      })
      $('.js-spinner-modal').modal('show')
    }
  })
})
