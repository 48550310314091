$(function() {
  if ($('body.users.show').length < 1) return

  // edit-proposal-btn
  $('.js-proposal-btn').on('click', function() {
    $('.js-edit-proposal-btn').attr('href', $(this).data('edit-path'))
    $('.js-control-modal').modal('show')
  })

  // edit-collection-btn
  $('.js-collection-btn').on('click', function() {
    $('.js-edit-collection-btn').attr('href', $(this).data('edit-path'))
    $('.js-collection-edit-modal').modal('show')
  })
})
