$(function() {
  if ($('body.all-shares.index').length < 1) return

  let loadPage = 0
  function setShareInfiniteLoader() {
    const intervalID = window.setInterval(function() {
      if ($('.js-list-share:eq(-2)').is(':appeared')) {
        window.clearInterval(intervalID)
        $('.js-infinite-spinner').show()
        getShares(loadPage++)
      }
    }, 2000)
  }

  function getShares(page) {
    $.ajax({
      type: 'GET',
      url: '/paging/shares',
      dataType: 'script',
      data: {
        page: page,
      },
    }).done(function() {
      setShareInfiniteLoader()
    }).always(function() {
      $('.js-infinite-spinner').hide()
    })
  }

  $('.js-infinite-spinner').show()
  getShares(loadPage++)
})
