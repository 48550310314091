$(function() {
  $('.js-create-proposal-like-btn').on('click', function() {
    const $this = $(this)
    const proposalId = $this.data('proposal-id')
    $.ajax({
      type: 'POST',
      url: `/proposals/${proposalId}/like`,
    }).done(function() {
      $this.hide()
      $this.next('.js-destroy-proposal-like-btn').removeClass('d-none').hide().fadeIn(600)
      const $likeNum = $(`.js-proposal-like-num[data-proposal-id="${proposalId}"]`)
      $likeNum.text(+$likeNum.text() + 1)
    }).fail(function() {
      window.alert(window.App.messages.errorAndTry)
    })
  })
  $('.js-destroy-proposal-like-btn').on('click', function() {
    const $this = $(this)
    const proposalId = $this.data('proposal-id')
    $.ajax({
      type: 'DELETE',
      url: `/proposals/${proposalId}/like`,
    }).done(function() {
      $this.hide()
      $this.prev('.js-create-proposal-like-btn').removeClass('d-none').hide().fadeIn(600)
      const $likeNum = $(`.js-proposal-like-num[data-proposal-id="${proposalId}"]`)
      $likeNum.text(+$likeNum.text() - 1)
    }).fail(function() {
      window.alert(window.App.messages.errorAndTry)
    })
  })

  $(document).on('click', '.js-create-collage-like-btn', function(e) {
    e.preventDefault()
    const $this = $(this)
    const collageId = $this.data('collage-id')
    $.ajax({
      type: 'POST',
      url: `/collages/${collageId}/like`,
    }).done(function() {
      $this.hide()
      $this.next('.js-destroy-collage-like-btn').removeClass('d-none').hide().fadeIn(600)
      const $likeNum = $(`.js-collage-like-num[data-collage-id="${collageId}"]`)
      $likeNum.text(+$likeNum.text() + 1)
    }).fail(function() {
      window.alert(window.App.messages.errorAndTry)
    })
  })
  $(document).on('click', '.js-destroy-collage-like-btn', function(e) {
    e.preventDefault()
    const $this = $(this)
    const collageId = $this.data('collage-id')
    $.ajax({
      type: 'DELETE',
      url: `/collages/${collageId}/like`,
    }).done(function() {
      $this.hide()
      $this.prev('.js-create-collage-like-btn').removeClass('d-none').hide().fadeIn(600)
      const $likeNum = $(`.js-collage-like-num[data-collage-id="${collageId}"]`)
      $likeNum.text(+$likeNum.text() - 1)
    }).fail(function() {
      window.alert(window.App.messages.errorAndTry)
    })
  })
})
