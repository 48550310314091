$(function() {
  if ($('body.asks.new').length < 1) return

  const productPhotoIdRegex = /^productPhoto(\d+)$/,
    productIdRegex = /^product(\d+)$/
  let prefixedId = null,
    productIdPhotoId = {}
  window.App.checkProducts = function() {
    if (productPhotoIdRegex.test(prefixedId)) {
      const photoId = productPhotoIdRegex.exec(prefixedId)[1],
        productId = App.util.getKeyByValue(productIdPhotoId, Number(photoId)),
        $checked = $(`.js-check-product-col[data-id="${productId}"] .js-check-product-check[data-check="1"]`)
      if ($checked.length) {
        $(`.js-check-product-col[data-id="${productId}"] img`).attr('src', $checked.first().closest('.js-check-product-col').find('img').attr('src'))
      }
      $(`.js-check-product-col[data-id="${productId}"] .js-check-product-check`).attr('data-check', '1')
    } else if (productIdRegex.test(prefixedId)) {
      $(`.js-check-product-col[data-id="${productIdRegex.exec(prefixedId)[1]}"] .js-check-product-check`).attr('data-check', '1')
    }
  }

  let activeBrandId = 0,
    activeGroupId = 0,
    activeVarietyId = 0

  const $checkProductsModalRow = $('.js-check-products-modal-row')
  function updateView(scrollBrand = false, scrollGroup = false, scrollVariety = false) {
    $('.js-product-brand').removeClass('active')
    $('.js-product-group').removeClass('active')
    $('.js-product-varieties').removeClass('active')
    $('.js-product-variety').removeClass('active')
    $(`.js-product-brand[data-brand-id="${activeBrandId}"]`).addClass('active')
    $(`.js-product-group[data-group-id="${activeGroupId}"]`).addClass('active')
    $(`.js-product-varieties[data-group-id="${activeGroupId}"]`).addClass('active')
    $(`.js-product-varieties[data-group-id="${activeGroupId}"] .js-product-variety[data-variety-id="${activeVarietyId}"]`).addClass('active')

    if (scrollBrand && $('.js-product-brand.active').length) $('.js-product-brands').scrollLeft(0).animate({ scrollLeft: $('.js-product-brand.active').position().left }, 600)
    if (scrollGroup && $('.js-product-group.active').length) $('.js-product-groups').scrollLeft(0).animate({ scrollLeft: $('.js-product-group.active').position().left }, 600)
    if (scrollVariety && $('.js-product-variety.active').length) $('.js-product-varieties').scrollLeft(0).animate({ scrollLeft: $('.js-product-variety.active').position().left }, 600)

    $checkProductsModalRow.children().hide()
    const $activeAppended = $checkProductsModalRow.children(`[data-brand-id="${activeBrandId}"][data-group-id="${activeGroupId}"][data-variety-id="${activeVarietyId}"]`)
    $activeAppended.fadeIn(400)
    window.App.checkProducts()
    if ($activeAppended.length) return
    $.ajax({
      type: 'GET',
      url: '/paging/asks/products',
      dataType: 'script',
      data: {
        page: 0,
        brand_id: activeBrandId,
        group_id: activeGroupId,
        variety_id: activeVarietyId,
      },
    }).done(function() {
      window.App.checkProducts()
    })
    $('.js-check-products-modal-spinner').show()
  }

  const $checkProductsModal = $('.js-check-products-modal')
  $('.js-show-check-products-modal').on('click', function() {
    $checkProductsModal.modal('show')
    updateView(true, true, true)
  })

  const $checkProductModal = $('.js-check-product-modal')
  $checkProductModal.on('hidden.bs.modal', function() {
    $('body').addClass('modal-open')
  })

  // product-brand
  $('.js-product-brand').on('click', function() {
    const newBrandId = $(this).data('brand-id')
    if (newBrandId === activeBrandId) return
    activeBrandId = newBrandId
    updateView()
  })

  // product-group
  $('.js-product-group').on('click', function() {
    const newGroupId = $(this).data('group-id')
    if (newGroupId === activeGroupId) return
    activeGroupId = newGroupId
    activeVarietyId = 0
    updateView(false, false, true)
  })

  // product-variety
  $('.js-product-variety').on('click', function() {
    const newVarietyId = $(this).data('variety-id')
    if (newVarietyId === activeVarietyId) return
    activeVarietyId = newVarietyId
    updateView()
  })

  // check-product-col
  const $spinnerModal = $('.js-spinner-modal')
  let currentProductId = null
  $(document).on('click', '.js-check-product-col', function() {
    const productId = $(this).data('id')
    if (currentProductId === productId) {
      $checkProductModal.modal('show')
    } else {
      getAskProduct(productId)
      currentProductId = productId
    }
  })
  function getAskProduct(productId) {
    $.ajax({
      method: 'GET',
      url: ('/asks/products/' + productId),
      dataType: 'script',
    }).done(function() {
      const photoId = productIdPhotoId[productId]
      if (photoId === 0) {
        $(`.js-check-product-photo[data-product-id="${productId}"][data-id="0"] .js-check-product-check`).attr('data-check', '1')
      } else if (photoId) {
        $(`.js-check-product-photo[data-product-id="${productId}"][data-id="${photoId}"] .js-check-product-check`).attr('data-check', '1')
      }
    })
    $spinnerModal.modal('show')
  }

  // check-product-photo
  const $checkedProduct = $('.js-checked-product'),
    $checkedProductImg = $checkedProduct.find('.js-checked-product-img'),
    $checkedProductBrand = $checkedProduct.find('.js-checked-product-brand'),
    $checkedProductName = $checkedProduct.find('.js-checked-product-name'),
    $checkedProductPrice = $checkedProduct.find('.js-checked-product-price'),
    $checkProductModalContent = $('.js-check-product-modal-content'),
    $addProduct = $('.js-add-product')
  $(document).on('click', '.js-check-product-photo', function() {
    const $this = $(this),
      photoId = $this.data('id'),
      productId = $this.data('product-id')
    prefixedId = null
    productIdPhotoId = {}
    $checkedProduct.children('input[type="hidden"]').remove()
    if ($this.find('.js-check-product-check').attr('data-check') === '0') {
      const $askProductCol = $(`.js-check-product-col[data-id="${productId}"]`),
        photoImgSrc = $this.find('img').attr('src')
      if (photoId === 0) {
        prefixedId = 'product' + productId
        $checkedProduct.append(`<input type="hidden" name="ask[product_id]" value="${productId}">`)
      } else {
        prefixedId = 'productPhoto' + photoId
        $checkedProduct.append(`<input type="hidden" name="ask[product_photo_id]" value="${photoId}">`)
      }
      productIdPhotoId[productId] = photoId
      $(`.js-check-product-col .js-check-product-check`).attr('data-check', '0')
      $askProductCol.find('.js-check-product-check').attr('data-check', '1')
      $(`.js-check-product-photo .js-check-product-check`).attr('data-check', '0')
      $(`.js-check-product-photo[data-id="${photoId}"] .js-check-product-check`).attr('data-check', '1')
      $askProductCol.find('img').attr('src', photoImgSrc)
      $checkedProductImg.attr('src', photoImgSrc)
      $checkedProductBrand.text($askProductCol.find('.js-check-product-brand').first().text())
      $checkedProductName.text($checkProductModalContent.find('.js-check-product-name').text())
      $checkedProductPrice.text($checkProductModalContent.find('.js-check-product-price').text())
      $addProduct.hide()
      $checkedProduct.show()
    } else {
      $(`.js-check-product-col[data-id="${productId}"] .js-check-product-check`).attr('data-check', '0')
      $(`.js-check-product-photo[data-product-id="${productId}"] .js-check-product-check`).attr('data-check', '0')
      $checkedProduct.hide()
      $addProduct.show()
    }
    $checkProductModal.modal('hide')
  })

  // look
  const $lookImg = $('.js-look-img')
  $('#js-look-file').on('change', function() {
    const file = event.target.files[0]
    if (!file) {
      $lookImg.removeAttr('src')
      return
    }
    const reader = new FileReader()
    reader.onload = function() {
      $lookImg.attr('src', reader.result)
    }
    reader.readAsDataURL(file)
  })

  // stylist-modal
  const $offerNotSelectLine = $('.js-offer-not-select-line'),
    $offerSelectedLines = $('.js-offer-selected-lines'),
    $offerSelect = $('.js-offer-select'),
    $stylistModalNotSelectIcon = $('.js-stylist-modal-not-select-icon'),
    $stylistModalStylistIcon = $('.js-stylist-modal-stylist-icon'),
    $stylistModal = $('.js-stylist-modal'),
    $stylistModalStylistRow = $('.js-stylist-modal-stylist-row')
  let checkedStylists = new Map()
  let selectedStylists = new Map()
  function updateCheck() {
    checkedStylists.size ? $stylistModalNotSelectIcon.removeClass('active') : $stylistModalNotSelectIcon.addClass('active')
    $stylistModalStylistIcon.removeClass('active')
    checkedStylists.forEach(function(stylist) {
      $(`.js-stylist-modal-stylist-row[data-id="${stylist.id}"] .js-stylist-modal-stylist-icon`).addClass('active')
    })
  }
  function updateSelection() {
    selectedStylists = new Map(checkedStylists)
    if (selectedStylists.size) {
      $offerNotSelectLine.removeClass('d-flex').addClass('d-none')
    } else {
      $offerNotSelectLine.removeClass('d-none').addClass('d-flex')
    }
    $('.js-offer-selected-line').remove()
    checkedStylists.forEach(function(stylist) {
      $offerSelectedLines.append(
        `<div class="ask__line js-offer-selected-line d-flex">
          <div class="ask__line__avatar">
            <img class="js-stylist-avatar" src="${stylist.avatar}">
          </div>
          <div class="ask__line__info">
            <p class="ask__line__info__username text-truncate js-stylist-username">${stylist.username}</p>
            <p class="text-truncate js-stylist-nickname">${stylist.nickname}</p>
          </div>
          <input type="hidden" name="ask[ask_offer_stylists_attributes][][offer_stylist_id]" value="${stylist.id}">
        </div>`
      )
    })
  }
  $offerSelect.on('click', function() {
    checkedStylists = new Map(selectedStylists)
    updateCheck()
    $stylistModal.modal('show')
  })
  $('.js-stylist-modal-done-btn').on('click', function() {
    updateSelection()
    $stylistModal.modal('hide')
  })
  $('.js-stylist-modal-not-select-row').on('click', function() {
    checkedStylists = new Map()
    updateCheck()
  })
  $stylistModalStylistRow.on('click', function() {
    const $this = $(this),
      id = $this.data('id')
    if (checkedStylists.has(id)) {
      checkedStylists.delete(id)
    } else {
      checkedStylists.set(id, {
        id: id,
        avatar: $this.find('.js-stylist-avatar').attr('src'),
        username: $this.find('.js-stylist-username').text(),
        nickname: $this.find('.js-stylist-nickname').text(),
      })
    }
    updateCheck()
  })
  updateSelection()

  // assortment-modal
  const $assortmentNotSelectLine = $('.js-assortment-not-select-line'),
    $assortmentSelectedLine = $('.js-assortment-selected-line'),
    $assortmentModalNotSelectIcon = $('.js-assortment-modal-not-select-icon i'),
    $assortmentModalAssortmentIcon = $('.js-assortment-modal-assortment-icon'),
    $assortmentModal = $('.js-assortment-modal')
  $assortmentNotSelectLine.on('click', function() {
    $assortmentModalAssortmentIcon.hide()
    $assortmentModalNotSelectIcon.show()
    $assortmentModal.modal('show')
  })
  $assortmentSelectedLine.on('click', function() {
    $assortmentModal.modal('show')
  })
  $('.js-assortment-modal-not-select-row').on('click', function() {
    $assortmentSelectedLine.children('.js-assortment-id').remove()
    $assortmentSelectedLine.removeClass('d-flex').addClass('d-none')
    $assortmentNotSelectLine.removeClass('d-none').addClass('d-flex')
    $assortmentModal.modal('hide')
  })
  $('.js-assortment-modal-group-col').on('click', function() {
    const $this = $(this),
      id = $this.data('id')
    if ($assortmentSelectedLine.children(`.js-group-id[value="${id}"]`).length < 1) {
      $assortmentModalNotSelectIcon.hide()
      $assortmentModalAssortmentIcon.hide()
      $this.find('.js-assortment-modal-assortment-icon').show()
      $assortmentSelectedLine.find('span').text($this.find('span').text())
      $assortmentSelectedLine.children('.js-assortment-id').remove()
      $assortmentSelectedLine.append(`<input type="hidden" class="js-assortment-id js-group-id" name="ask[ask_assortments_attributes][0][group_id]" value="${id}">`)
      $assortmentNotSelectLine.removeClass('d-flex').addClass('d-none')
      $assortmentSelectedLine.removeClass('d-none').addClass('d-flex')
    }
    $assortmentModal.modal('hide')
  })
  $('.js-assortment-modal-variety-col').on('click', function() {
    const $this = $(this),
      id = $this.data('id')
    if ($assortmentSelectedLine.children(`.js-variety-id[value="${id}"]`).length < 1) {
      $assortmentModalNotSelectIcon.hide()
      $assortmentModalAssortmentIcon.hide()
      $this.find('.js-assortment-modal-assortment-icon').show()
      $assortmentSelectedLine.find('span').text($this.find('span').text())
      $assortmentSelectedLine.children('.js-assortment-id').remove()
      $assortmentSelectedLine.append(`<input type="hidden" class="js-assortment-id js-variety-id" name="ask[ask_assortments_attributes][0][variety_id]" value="${id}">`)
      $assortmentNotSelectLine.removeClass('d-flex').addClass('d-none')
      $assortmentSelectedLine.removeClass('d-none').addClass('d-flex')
    }
    $assortmentModal.modal('hide')
  })

  // submit
  const $askCaption = $('.js-ask-caption'),
    $textModalBody = $('.js-text-modal-body'),
    invalidTextP = '<p>相談内容を入力してください</p>',
    $textModal = $('.js-text-modal')
  $('.js-create-ask-btn').on('click', function() {
    if ($askCaption.val().trim()) {
      Rails.fire(document.querySelector('.js-ask-form'), 'submit')
      $('.js-spinner-modal').modal('show')
    } else {
      $textModalBody.html(invalidTextP)
      $textModal.modal('show')
    }
  })
})
