import REGEX from 'packs/data/REGEX'

export default {
  validEmail: function(email) {
    return REGEX.email.test(email)
  },
  controlBirthDateInput: function() {
    const $birthDateDay = $('.js-birth-date-day')
    disableInvalidDays()

    $('.js-birth-date-year, .js-birth-date-month').on('change', function() {
      disableInvalidDays()
    })

    function disableInvalidDays() {
      const days = new Date(parseInt($('.js-birth-date-year').val()), parseInt($('.js-birth-date-month').val()), 0).getDate(),
        day = parseInt($('.js-birth-date-day').val())
      if (days < day) $birthDateDay.val(1)
      $birthDateDay.children('option').prop('disabled', false)
      for (let currentDay = days + 1; currentDay <= 31; currentDay++) {
        $birthDateDay.children('option[value="' + currentDay + '"]').prop('disabled', true)
      }
    }
  },
  getKeyByValue: function(obj, value) {
    return Object.keys(obj).find(key => obj[key] === value)
  },
  localStorageGetItem: function(key) {
    try {
      return localStorage.getItem(key)
    } catch (error) {
      return null
    }
  },
  localStorageSetItem: function(key, value) {
    try {
      return localStorage.setItem(key, value)
    } catch (error) {
      return null
    }
  },
  photoSwiperInit: function() {
    if ($('.js-thumb-container').length) {
      const thumbContainerSwiper = new Swiper('.js-thumb-container', {
        spaceBetween: 0,
        slidesPerView: 5,
        loop: false,
        freeMode: false,
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
      })
      new Swiper('.js-photo-container', {
        spaceBetween: 0,
        slidesPerView: 1,
        loop: false,
        freeMode: true,
        freeModeSticky: true,
        navigation: {
          nextEl: '.js-swiper-next',
          prevEl: '.js-swiper-prev',
        },
        thumbs: {
          swiper: thumbContainerSwiper,
        },
      })
    }
  },
  snapSwiperInit: function() {
    if ($('.js-swiper-pagination').length) {
      new Swiper('.js-snap-container', {
        spaceBetween: 0,
        slidesPerView: 'auto',
        loop: false,
        freeMode: true,
        freeModeSticky: true,
        pagination: {
          el: '.js-swiper-pagination',
          clickable: true,
        },
        navigation: {
          nextEl: '.js-swiper-next',
          prevEl: '.js-swiper-prev',
        },
      })
    } else {
      new Swiper('.js-snap-container', {
        spaceBetween: 0,
        slidesPerView: 'auto',
      })
    }
  },
  sendProductView: function({
    apiGatewayUrl,
    apiGatewayApiKey,
    siteId,
    userId,
    productCodes,
    proposalId = null,
    askProductCode = null,
    collectionId = null,
    collageId = null,
    styleId = null,
  }) {
    if (siteId && productCodes.length) {
      $.ajax({
        method: 'POST',
        url: apiGatewayUrl + '/product-view',
        headers: {
          'X-Api-Key': apiGatewayApiKey,
          'Content-Type': 'application/json',
        },
        data: JSON.stringify({
          client_id: App.util.localStorageGetItem('clientId') || 'anonymous-client',
          site_id: siteId,
          user_id: userId,
          view_type: 4,
          product_codes: productCodes,
          proposal_id: proposalId,
          ask_product_code: askProductCode,
          collection_id: collectionId,
          share_id: null,
          collage_id: collageId,
          style_id: styleId,
        }),
      })
    }
  },
}
