$(function() {
  if ($('body.all-styles.index').length < 1) return

  const siteId = $('.js-home-sites').data('site-id')

  let loadPage = 0
  function setStyleInfiniteLoader() {
    const intervalID = window.setInterval(function() {
      if ($('.js-style-col:eq(-2)').is(':appeared')) {
        window.clearInterval(intervalID)
        $('.js-infinite-spinner').show()
        getStyles(loadPage++)
      }
    }, 2000)
  }

  function getStyles(page) {
    $.ajax({
      type: 'GET',
      url: '/paging/styles',
      dataType: 'script',
      data: {
        page: page,
        site_id: siteId,
      },
    }).done(function() {
      setStyleInfiniteLoader()
    }).always(function() {
      $('.js-infinite-spinner').hide()
    })
  }

  // init
  $(`.js-home-site[data-site-id="${siteId}"]`).addClass('active')
  $('.js-infinite-spinner').show()
  getStyles(loadPage++)
})
