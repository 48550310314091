$(function() {
  if ($('body.asks-proposals.collect, body.collections.collect, body.shares.collect').length < 1) return
  const isCollection = $('body.collections.collect').length > 0
  const siteId = window.gon.siteId
  const isShare = $('body.shares.collect').length > 0

  const styleIdRegex = /^style(\d+)$/,
    productPhotoIdRegex = /^productPhoto(\d+)$/,
    productIdRegex = /^product(\d+)$/
  const prefixedIdsKey = `${isCollection ? `collection_site${siteId}_` : ''}prefixedIds`,
    productIdPhotoIdKey = `${isCollection ? `collection_site${siteId}_` : ''}productIdPhotoId`,
    selectedItemListKey = `${isCollection ? `collection_site${siteId}_` : ''}selectedItemList`
  let prefixedIds = sessionStorage.getItem(prefixedIdsKey),
    productIdPhotoId = sessionStorage.getItem(productIdPhotoIdKey),
    selectedItemList = sessionStorage.getItem(selectedItemListKey)

  prefixedIds = prefixedIds ? JSON.parse(prefixedIds) : []
  productIdPhotoId = productIdPhotoId ? JSON.parse(productIdPhotoId) : {}
  selectedItemList = selectedItemList ? JSON.parse(selectedItemList) : []

  window.App.checkItems = function() {
    prefixedIds.forEach(function(id) {
      if (styleIdRegex.test(id)) {
        $(`.js-collect-style-col[data-id="${styleIdRegex.exec(id)[1]}"] .js-collect-item-check`).attr('data-check', '1')
      } else if (productPhotoIdRegex.test(id)) {
        const photoId = productPhotoIdRegex.exec(id)[1],
          productId = App.util.getKeyByValue(productIdPhotoId, Number(photoId)),
          prefixedPhotoId = 'productPhoto' + photoId,
          selectedPhoto = selectedItemList.find(selectedItem => selectedItem.prefixedId === prefixedPhotoId)
        if (selectedPhoto) {
          $(`.js-collect-product-col[data-id="${productId}"] img`).attr('src', selectedPhoto.src)
        }
        $(`.js-collect-product-col[data-id="${productId}"] .js-collect-item-check`).attr('data-check', '1')
      } else if (productIdRegex.test(id)) {
        $(`.js-collect-product-col[data-id="${productIdRegex.exec(id)[1]}"] .js-collect-item-check`).attr('data-check', '1')
      }
    })
  }

  let activeSex = window.gon.sex,
    activeType = 'product',
    activeBrandId = '0',
    activeGroupId = window.gon.groupId,
    activeVarietyId = window.gon.varietyId,
    activeColorId = '0',
    activeHeightId = '0',
    activeProductName = ''

  const $collectItems = $('.js-collect-items'),
    fetches = []
  function updateView(scrollBrand = false, scrollGroup = false, scrollVariety = false, scrollColor = false, scrollHeight = false) {
    $('.js-item-sex').removeClass('active')
    $('.js-item-type').removeClass('active')
    $('.js-item-brand').removeClass('active')
    $('.js-item-group').removeClass('active')
    $('.js-item-varieties').removeClass('active')
    $('.js-item-variety').removeClass('active')
    $('.js-item-color').removeClass('active')
    $('.js-item-height').removeClass('active')
    $(`.js-item-sex[data-sex="${activeSex}"]`).addClass('active')
    $(`.js-item-type[data-type="${activeType}"]`).addClass('active')
    $(`.js-item-brand[data-brand-id="${activeBrandId}"]`).addClass('active')
    $(`.js-item-group[data-group-id="${activeGroupId}"]`).addClass('active')
    $(`.js-item-varieties[data-group-id="${activeGroupId}"]`).addClass('active')
    $(`.js-item-varieties[data-group-id="${activeGroupId}"] .js-item-variety[data-variety-id="${activeVarietyId}"]`).addClass('active')
    $(`.js-item-color[data-color-id="${activeColorId}"]`).addClass('active')
    $(`.js-item-height[data-height-id="${activeHeightId}"]`).addClass('active')

    if (activeType === 'style') {
      $('.js-item-groups, .js-item-varieties, .js-item-product-name-and-color').hide()
      $('.js-item-heights').show()
    } else {
      $('.js-item-heights').hide()
      $('.js-item-groups, .js-item-varieties.active, .js-item-product-name-and-color').show()
    }

    if (scrollBrand && $('.js-item-brand.active').length) $('.js-item-brands').scrollLeft(0).animate({ scrollLeft: $('.js-item-brand.active').position().left }, 600)
    if (scrollGroup && $('.js-item-group.active').length) $('.js-item-groups').scrollLeft(0).animate({ scrollLeft: $('.js-item-group.active').position().left }, 600)
    if (scrollVariety && $('.js-item-variety.active').length) $('.js-item-varieties').scrollLeft(0).animate({ scrollLeft: $('.js-item-variety.active').position().left }, 600)
    if (scrollColor && $('.js-item-color.active').length) $('.js-item-colors').scrollLeft(0).animate({ scrollLeft: $('.js-item-color.active').position().left }, 600)
    if (scrollHeight && $('.js-item-height.active').length) $('.js-item-heights').scrollLeft(0).animate({ scrollLeft: $('.js-item-height.active').position().left }, 600)

    $collectItems.children().hide()
    const fetch = activeType === 'style' ?
      `style_${activeSex}_${activeBrandId}_${activeHeightId}` :
      `product_${activeSex}_${activeBrandId}_${activeGroupId}_${activeVarietyId}_${activeColorId}_${activeProductName}`
    const $activeAppended = $collectItems.children(`[data-fetch-index="${fetches.indexOf(fetch)}"]`)
    $activeAppended.fadeIn(400)

    window.App.checkItems()

    if ($activeAppended.length) return
    const newFetchIndex = fetches.length
    fetches.push(fetch)
    $.ajax({
      type: 'GET',
      url: activeType === 'style' ? '/paging/items/styles' : '/paging/items/products',
      dataType: 'script',
      data: {
        page: 0,
        fetch_index: newFetchIndex,
        sex: activeSex,
        brand_id: activeBrandId,
        group_id: activeGroupId,
        variety_id: activeVarietyId,
        color_id: activeColorId,
        height_id: activeHeightId,
        product_name: activeProductName,
        site_id: siteId || 0,
      },
    }).done(function() {
      window.App.checkItems()
    })
    $('.js-infinite-spinner').show()
  }

  // item-sex
  $('.js-item-sex').on('click', function() {
    const newSex = $(this).data('sex')
    if (newSex === activeSex) return
    activeSex = newSex
    updateView()
  })

  // item-type
  $('.js-item-type').on('click', function() {
    const newType = $(this).data('type')
    if (newType === activeType) return
    activeType = newType
    updateView(true, true, true, true, true)
  })

  // item-brand
  $('.js-item-brand').on('click', function() {
    const newBrandId = $(this).data('brand-id')
    if (newBrandId === activeBrandId) return
    activeBrandId = newBrandId
    updateView()
  })

  // item-group
  $('.js-item-group').on('click', function() {
    const newGroupId = $(this).data('group-id')
    if (newGroupId === activeGroupId) return
    activeGroupId = newGroupId
    activeVarietyId = 0
    updateView(false, false, true, false, false)
  })

  // item-variety
  $('.js-item-variety').on('click', function() {
    const newVarietyId = $(this).data('variety-id')
    if (newVarietyId === activeVarietyId) return
    activeVarietyId = newVarietyId
    updateView()
  })

  // item-color
  $('.js-item-color').on('click', function() {
    const newColorId = $(this).data('color-id')
    if (newColorId === activeColorId) return
    activeColorId = newColorId
    updateView()
  })

  // item-height
  $('.js-item-height').on('click', function() {
    const newHiehgtId = $(this).data('height-id')
    if (newHiehgtId === activeHeightId) return
    activeHeightId = newHiehgtId
    updateView()
  })

  // brand-search
  const $brandSearchModal = $('.js-brand-search-modal')
  $(document).on('click', '.js-item-brand-search', function() {
    $brandSearchModal.modal('show')
  })

  $(document).on('click', '.js-brand-search-modal-close-button', function() {
    $brandSearchModal.modal('hide')
  })

  $(document).on('click', '.js-brand-initial', function() {
    $('.js-brand-initial').removeClass('active')
    $(this).addClass('active')
    const container = $('.brand-list__brand-list')
    const containerPosition = container.offset().top
    const initialGroup = $(`.js-brand-initial-group[data-brand-initial="${$(this).data('brand-initial')}"]`)
    const brandPosition = initialGroup.offset().top
    container.animate({
      scrollTop: container.scrollTop() + (brandPosition - containerPosition),
    })
  })

  $(document).on('input', '.js-brand-search-input', function() {
    const brandInitialList = []
    const pattern = new RegExp($(this).val().trim(), 'i')

    $('.js-brand-search-item').each(function(i, item) {
      const $item = $(item)
      if(pattern.test($item.data('name')) || pattern.test($item.data('kanaName'))) {
        $item.show()
        brandInitialList.push($item.data('brandInitial'))
      } else {
        $item.hide()
      }
    })
    $('.js-brand-initial-group').each(function(i, item) {
      const $item = $(item)
      if(brandInitialList.includes($item.data('brandInitial'))) {
        $item.show()
      } else {
        $item.hide()
      }
    })
    $('.js-brand-initial').each(function(i, item) {
      const $item = $(item)
      if(brandInitialList.includes($item.data('brandInitial'))) {
        $item.parent().show()
      } else {
        $item.parent().hide()
      }
    })
  })

  $(document).on('click', '.js-brand-search-item', function() {
    const newBrandId = $(this).data('brand-id')
    activeBrandId = newBrandId
    $brandSearchModal.modal('hide')
    updateView(true)
  })

  // product-name
  const $productNameModal = $('.js-product-name-modal')
  const $itemProductNameInput = $('.js-item-product-name-input')
  const updateProductName = function(productName) {
    $productNameModal.modal('hide')
    activeProductName = productName
    $itemProductNameInput.val(productName)
    updateView()
  }

  const productNameLocalStoragekey = 'proposal_product_name_search_history'
  const getProductNameHistory = function() {
    const json = localStorage.getItem(productNameLocalStoragekey)
    return json ? JSON.parse(json) : []
  }

  const addProductNameHistory = function(productName) {
    const histories = getProductNameHistory()
    const index = histories.indexOf(productName)
    if (index >= 0) {
      histories.splice(index, 1)
    }
    histories.unshift(productName)
    localStorage.setItem(productNameLocalStoragekey, JSON.stringify(histories))
  }

  const removeProductNameHistory = function(productName) {
    const histories = getProductNameHistory()
    const index = histories.indexOf(productName)
    if (index >= 0) {
      histories.splice(index, 1)
    }
    localStorage.setItem(productNameLocalStoragekey, JSON.stringify(histories))
  }

  const $productNameModalSearchHistories = $('.js-product-name-modal-search-histories')
  const $productNameModalSearchHistory = $('.js-product-name-modal-search-history')
  const showSearchHistories = function() {
    const histories = getProductNameHistory()
    $productNameModalSearchHistories.empty()
    histories.forEach(function(productName) {
      const $searchHistory = $productNameModalSearchHistory.clone(true)
      $searchHistory.removeClass('d-none')
      $searchHistory.find('.js-product-name-modal-search-history-product-name').text(productName)
      $productNameModalSearchHistories.append($searchHistory)
    })
  }

  $(document).on('click', '.js-product-name-modal-search-history-box', function() {
    const productName = $(this).find('.js-product-name-modal-search-history-product-name').text()
    updateProductName(productName)
    addProductNameHistory(productName)
  })

  const $productNameModalInput = $('.js-product-name-modal-input')
  $(document).on('click', '.js-item-product-name-btn', function() {
    $productNameModalInput.val(activeProductName)
    showSearchHistories()
    $productNameModal.modal('show')
  })

  $(document).on('click', '.js-product-name-modal-remove-search-history', function() {
    const $searchHistory = $(this).closest('.js-product-name-modal-search-history')
    const productName = $searchHistory.find('.js-product-name-modal-search-history-product-name').text()
    removeProductNameHistory(productName)
    $searchHistory.remove()
  })

  let isComposing = false
  $productNameModalInput.on('compositionstart', function() {
    isComposing = true
  })
  $productNameModalInput.on('compositionend', function() {
    // safariでは compositionend の後に keydown が発火してしまうため遅延させる
    window.setTimeout(function() {
      isComposing = false
    }, 100)
  })
  $productNameModalInput.on('keydown', function(e) {
    if (e.key === 'Enter') {
      if (!isComposing) {
        const productName = $productNameModalInput.val().trim()
        if (productName) {
          updateProductName(productName)
          addProductNameHistory(productName)
          $productNameModalInput.blur()
        }
      }
      return false
    }
  })

  $(document).on('click', '.js-product-name-modal-cancel-btn', function() {
    $productNameModalInput.val('')
  })

  $(document).on('click', '.js-product-name-modal-close-btn', function() {
    $productNameModal.modal('hide')
  })

  $(document).on('click', '.js-product-name-modal-clear-btn', function() {
    updateProductName('')
  })

  function getProduct(productId) {
    $.ajax({
      method: 'GET',
      url: ('/items/products/' + productId),
      dataType: 'script',
    }).done(function() {
      const photoId = productIdPhotoId[productId]
      if (photoId === 0) {
        $(`.js-collect-product-photo[data-product-id="${productId}"][data-id="0"] .js-collect-item-check`).attr('data-check', '1')
      } else if (photoId) {
        $(`.js-collect-product-photo[data-product-id="${productId}"][data-id="${photoId}"] .js-collect-item-check`).attr('data-check', '1')
      }
    })
    $('.js-spinner-modal').modal('show')
  }

  const $checkProductModal = $('.js-check-product-modal')
  // collect-product-col, footer-selected-product
  let currentProductId = null
  $(document).on('click', '.js-collect-product-col, .js-footer-selected-product', function() {
    const productId = $(this).data('id')
    if (isShare) {
      window.location.href = `/shares/new?site_id=${siteId}&product_id=${productId}`
    } else if (currentProductId === productId) {
      $checkProductModal.modal('show')
    } else {
      getProduct(productId)
      currentProductId = productId
    }
  })

  const $footerSelectedItems = $('.js-footer-selected-items')
  const $footerSelectedStyle = $('.js-footer-selected-style')
  const $footerSelectedProduct = $('.js-footer-selected-product')
  function updateFooterSelectedItems() {
    $footerSelectedItems.empty()
    selectedItemList.forEach(function(selectedItem) {
      if (styleIdRegex.test(selectedItem.prefixedId)) {
        const $selectedStyle = $footerSelectedStyle.clone(true)
        $selectedStyle.removeClass('d-none')
        $selectedStyle.find('.js-footer-selected-item-img').attr('src', selectedItem.src)
        $footerSelectedItems.append($selectedStyle)
      } else if (productPhotoIdRegex.test(selectedItem.prefixedId)) {
        const photoId = productPhotoIdRegex.exec(selectedItem.prefixedId)[1]
        const productId = App.util.getKeyByValue(productIdPhotoId, Number(photoId))
        const $selectedProduct = $footerSelectedProduct.clone(true)
        $selectedProduct.removeClass('d-none')
        $selectedProduct.find('.js-footer-selected-item-img').attr('src', selectedItem.src)
        $selectedProduct.data('id', Number(productId))
        $footerSelectedItems.append($selectedProduct)
      } else if (productIdRegex.test(selectedItem.prefixedId)) {
        const $selectedProduct = $footerSelectedProduct.clone(true)
        $selectedProduct.removeClass('d-none')
        $selectedProduct.find('.js-footer-selected-item-img').attr('src', selectedItem.src)
        $selectedProduct.data('id', Number(productIdRegex.exec(selectedItem.prefixedId)[1]))
        $footerSelectedItems.append($selectedProduct)
      }
    })
  }

  function addSelectedItem(prefixedId, src) {
    selectedItemList.push({ prefixedId: prefixedId, src: src })
    sessionStorage.setItem(selectedItemListKey, JSON.stringify(selectedItemList))
    updateFooterSelectedItems()
  }

  function removeSelectedItem(prefixedId) {
    selectedItemList = selectedItemList.filter(selectedItem => selectedItem.prefixedId !== prefixedId)
    sessionStorage.setItem(selectedItemListKey, JSON.stringify(selectedItemList))
    updateFooterSelectedItems()
  }

  const $footerItemCount = $('.js-footer-item-count')
  // item-check
  $(document).on('click', '.js-collect-style-col', function() {
    const $this = $(this),
      $collectItemCheck = $this.find('.js-collect-item-check'),
      styleId = $this.data('id'),
      prefixedId = 'style' + styleId
    if ($collectItemCheck.attr('data-check') === '0') {
      if (7 < prefixedIds.length) return
      prefixedIds.push(prefixedId)
      addSelectedItem(prefixedId, $collectItemCheck.prev('img').attr('src'))
      $(`.js-collect-style-col[data-id="${styleId}"] .js-collect-item-check`).attr('data-check', '1')
    } else {
      prefixedIds = prefixedIds.filter(id => id !== prefixedId)
      removeSelectedItem(prefixedId)
      $(`.js-collect-style-col[data-id="${styleId}"] .js-collect-item-check`).attr('data-check', '0')
    }
    sessionStorage.setItem(prefixedIdsKey, JSON.stringify(prefixedIds))
    $footerItemCount.text(prefixedIds.length)
  })
  $(document).on('click', '.js-collect-product-photo', function() {
    const $this = $(this),
      $collectItemCheck = $this.find('.js-collect-item-check'),
      photoId = $this.data('id'),
      productId = $this.data('product-id'),
      prefixedProductId = 'product' + productId,
      prefixedId = photoId === 0 ? prefixedProductId : ('productPhoto' + photoId)
    if ($collectItemCheck.attr('data-check') === '0') {
      if (window.gon.maxItemCount <= prefixedIds.length) return
      const prevPhotoId = productIdPhotoId[productId]
      if (prevPhotoId === 0) {
        prefixedIds = prefixedIds.filter(id => id !== prefixedProductId)
        removeSelectedItem(prefixedProductId)
      } else if (prevPhotoId) {
        const prevPrefixedPhotoId = 'productPhoto' + prevPhotoId
        prefixedIds = prefixedIds.filter(id => id !== prevPrefixedPhotoId)
        removeSelectedItem(prevPrefixedPhotoId)
      }
      prefixedIds.push(prefixedId)
      productIdPhotoId[productId] = photoId
      addSelectedItem(prefixedId, $collectItemCheck.prev('img').attr('src'))
      $(`.js-collect-product-col[data-id="${productId}"] .js-collect-item-check`).attr('data-check', '1')
      $(`.js-collect-product-photo[data-product-id="${productId}"] .js-collect-item-check`).attr('data-check', '0')
      $(`.js-collect-product-photo[data-id="${photoId}"] .js-collect-item-check`).attr('data-check', '1')
      $(`.js-collect-product-col[data-id="${productId}"] img`).attr('src', $this.find('img').attr('src'))
    } else {
      prefixedIds = prefixedIds.filter(id => id !== prefixedId)
      delete productIdPhotoId[productId]
      removeSelectedItem(prefixedId)
      $(`.js-collect-product-col[data-id="${productId}"] .js-collect-item-check`).attr('data-check', '0')
      $(`.js-collect-product-photo[data-product-id="${productId}"] .js-collect-item-check`).attr('data-check', '0')
    }
    sessionStorage.setItem(prefixedIdsKey, JSON.stringify(prefixedIds))
    sessionStorage.setItem(productIdPhotoIdKey, JSON.stringify(productIdPhotoId))
    $footerItemCount.text(prefixedIds.length)
    $checkProductModal.modal('hide')
  })

  // footer-next
  const $textModal = $('.js-text-modal'),
    $textModalBody = $('.js-text-modal-body'),
    invalidTextP = '<p>アイテムを選択してください</p>'
  $('.js-footer-next').on('click', function() {
    if (prefixedIds.length) {
      const url = new URL(`${window.location.protocol}//${window.location.host}${$(this).data('path')}`)
      prefixedIds.forEach(function(prefixedId) {
        url.searchParams.append('ids[]', prefixedId)
      })
      window.location.href = url
    } else {
      $textModalBody.html(invalidTextP)
      $textModal.modal('show')
    }
  })

  // init
  $footerItemCount.text(prefixedIds.length)
  updateView(true, true, true, true, true)
  updateFooterSelectedItems()
})
