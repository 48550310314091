$(function() {
  const $inputTagCards = $('.js-input-tag-cards')
  let tags = $inputTagCards.data('tags') || JSON.parse(sessionStorage.getItem('inputTags'))
  tags = tags ? tags.filter(tag => tag) : []
  function updateView() {
    $inputTagCards.empty()
    tags.forEach(function(tag) {
      $inputTagCards.append(`
        <div class="tag-card">
          <p>
            <span>#</span>
            <span class="js-tag-name">${tag}</span>
          </p>
          <i class="material-icons js-remove-tag" data-tag="${tag}">close</i>
          <input type="hidden" name="tags[]" value="${tag}">
        </div>
      `)
    })
  }
  function addTag() {
    const tag = $('.js-input-tag').val().trim()
    if (tag) {
      $('.js-input-tag').val('')
      if (!tags.includes(tag)) tags.push(tag)
      updateView()
    }
  }

  $('.js-add-tag').on('click', function() {
    addTag()
  })

  let isComposing = false
  $('.js-input-tag').on('compositionstart', function() {
    isComposing = true
  })
  $('.js-input-tag').on('compositionend', function() {
    // safariでは compositionend の後に keydown が発火してしまうため遅延させる
    window.setTimeout(function() {
      isComposing = false
    }, 100)
  })
  $('.js-input-tag').on('keydown', function(e) {
    if (e.key === 'Enter') {
      if (!isComposing) addTag()
      return false
    }
  })

  $(document).on('click', '.js-remove-tag', function() {
    const removeTag = $(this).data('tag')
    tags = tags.filter(tag => tag !== removeTag)
    updateView()
  })

  // init
  updateView()
})
