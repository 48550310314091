$(function() {
  if ($('body.collections.update, body.collections.create').length < 1) return

  const siteId = window.gon.siteId
  const prefixedIdsKey = `collection_site${siteId}_prefixedIds`,
    collectionTagsKey = `collection_site${siteId}_collectionTags`
  let prefixedIds = sessionStorage.getItem(prefixedIdsKey)
  prefixedIds = prefixedIds ? JSON.parse(prefixedIds) : []
  let collectionTags = sessionStorage.getItem(collectionTagsKey)
  collectionTags = collectionTags ? JSON.parse(collectionTags) : []

  $('.js-back-collection-link').on('click', function() {
    const url = new URL(`${window.location.protocol}//${window.location.host}${$(this).data('path')}`)
    prefixedIds.forEach(function(prefixedId) {
      url.searchParams.append('ids[]', prefixedId)
    })
    collectionTags.forEach(function(collectionTag) {
      url.searchParams.append('tags[]', collectionTag)
    })
    window.location.href = url
  })

  let productPhotoIdx = 0,
    productIdx = 0
  $('.js-collection-item-col').each(function(i) {
    const $this = $(this)
    $this.find('.js-item-number').text(i + 1)
    if ($this.data('prefixed-id').startsWith('productPhoto')) {
      $this.find('.js-input-product-photo-id').attr('name', `collection[collection_product_photos_attributes][${productPhotoIdx}][product_photo_id]`)
      $this.find('.js-input-product-photo-order').attr('name', `collection[collection_product_photos_attributes][${productPhotoIdx}][display_order]`)
      $this.find('.js-input-product-photo-order').attr('value', i + 1)
      productPhotoIdx++
    } else {
      $this.find('.js-input-product-id').attr('name', `collection[collection_products_attributes][${productIdx}][product_id]`)
      $this.find('.js-input-product-order').attr('name', `collection[collection_products_attributes][${productIdx}][display_order]`)
      $this.find('.js-input-product-order').attr('value', i + 1)
      productIdx++
    }
  })

  $('.js-submit-collection-btn').on('click', function() {
    Rails.fire(document.querySelector('.js-collection-form'), 'submit')
    $('.js-spinner-modal').modal('show')
  })
})
