$(function() {
  if ($('body.styles.new, body.styles.edit, body.styles.create, body.styles.update').length < 1) return

  const $snapAdditionDiv = $('.js-snap-addition-div')
  function updateSnapAddition() {
    const addedLength = $('.js-snap-added-div:visible').length
    if (9 < addedLength) {
      $snapAdditionDiv.hide()
    } else {
      $snapAdditionDiv.show()
    }
  }

  // init
  function setSnapAddedHidden($that) {
    const img = $that.children('.js-snap-img').get(0),
      canvas = document.createElement('canvas')
    canvas.width = img.naturalWidth
    canvas.height = img.naturalHeight
    canvas.getContext('2d').drawImage(img, 0, 0)
    $that.children('input[type="hidden"]').val(canvas.toDataURL('image/png'))
  }
  $('.js-snap-added-div').each(function() {
    const $this = $(this),
      $snapImg = $this.children('.js-snap-img')
    if (!$snapImg.attr('src')) return false
    $snapImg.one('load', function() {
      setSnapAddedHidden($this)
    }).each(function() {
      if (this.complete) $(this).trigger('load')
    })
    $this.show()
  })
  updateSnapAddition()

  // snap
  const $snapModal = $('.js-snap-modal')
  let croppieImg = null,
    snapSrc = null
  $('#js-snap-file').on('change', function(event) {
    const file = event.target.files[0]
    if (!file) return
    const reader = new FileReader()
    reader.onload = function() {
      snapSrc = reader.result
      if (croppieImg) {
        croppieImg.croppie('destroy')
        croppieImg.attr('src', '')
      }
      $snapModal.modal('show')
    }
    reader.readAsDataURL(file)
    event.target.value = null
  })
  $snapModal.on('shown.bs.modal', function() {
    croppieImg = $('.js-snap-modal-img').attr('src', snapSrc).croppie({
      viewport: { width: 300, height: 400, type: 'square' },
      boundary: { width: 300, height: 400 },
      enableExif: true,
      enforceBoundary: false,
    })
  })
  $('.js-snap-modal-btn').on('click', function() {
    croppieImg.croppie('result', { type: 'base64', size: { width: 600, height: 800 } }).then(function(cropped) {
      const $snapAddedDiv = $('.js-snap-added-div:hidden').first()
      $snapAddedDiv.children('.js-snap-img').attr('src', cropped)
      $snapAddedDiv.children('input[type="hidden"]').val(cropped).prop('disabled', false)
      $snapAddedDiv.show()
      updateSnapAddition()
      $snapModal.modal('hide')
    })
  })
  $(document).on('click', '.js-snap-remove-div', function() {
    const $snapAddedDiv = $(this).closest('.js-snap-added-div'),
      $nextAll = $snapAddedDiv.nextAll(':visible')
    if ($nextAll.length) {
      $nextAll.each(function() {
        const $this = $(this),
          $prev = $this.prev()
        $prev.children('.js-snap-img').attr('src', $this.children('.js-snap-img').attr('src'))
        $prev.children('input[type="hidden"]').val($this.children('input[type="hidden"]').val())
      })
      const $lastNext = $nextAll.last()
      $lastNext.children('.js-snap-img').attr('src', '')
      $lastNext.children('input[type="hidden"]').val('').prop('disabled', true)
      $lastNext.hide()
    } else {
      $snapAddedDiv.children('.js-snap-img').attr('src', '')
      $snapAddedDiv.children('input[type="hidden"]').val('').prop('disabled', true)
      $snapAddedDiv.hide()
    }
    updateSnapAddition()
  })

  // style-product
  const $styleProductRow = $('.js-style-product-row')
  let products = window.gon.products
  window.App.checkProducts = function() {
    products.forEach(function(product) {
      $(`.js-check-product-col[data-id="${product.id}"] .js-check-product-check`).attr('data-check', '1')
      $(`.js-check-product-color[data-product-color-id="${product.colorId}"]`).siblings().attr('data-check', '0')
      $(`.js-check-product-color[data-product-color-id="${product.colorId}"]`).attr('data-check', '1')
      $(`.js-check-product-size[data-product-size-id="${product.sizeId}"]`).siblings().attr('data-check', '0')
      $(`.js-check-product-size[data-product-size-id="${product.sizeId}"]`).attr('data-check', '1')
    })
  }

  let activeBrandId = 0,
    activeGroupId = 0,
    activeVarietyId = 0
  const siteId = $('.js-site-id').val()

  const $checkProductsModalRow = $('.js-check-products-modal-row')
  function updateView(scrollBrand = false, scrollGroup = false, scrollVariety = false) {
    $('.js-product-brand').removeClass('active')
    $('.js-product-group').removeClass('active')
    $('.js-product-varieties').removeClass('active')
    $('.js-product-variety').removeClass('active')
    $(`.js-product-brand[data-brand-id="${activeBrandId}"]`).addClass('active')
    $(`.js-product-group[data-group-id="${activeGroupId}"]`).addClass('active')
    $(`.js-product-varieties[data-group-id="${activeGroupId}"]`).addClass('active')
    $(`.js-product-varieties[data-group-id="${activeGroupId}"] .js-product-variety[data-variety-id="${activeVarietyId}"]`).addClass('active')

    if (scrollBrand && $('.js-product-brand.active').length) $('.js-product-brands').scrollLeft(0).animate({ scrollLeft: $('.js-product-brand.active').position().left }, 600)
    if (scrollGroup && $('.js-product-group.active').length) $('.js-product-groups').scrollLeft(0).animate({ scrollLeft: $('.js-product-group.active').position().left }, 600)
    if (scrollVariety && $('.js-product-variety.active').length) $('.js-product-varieties').scrollLeft(0).animate({ scrollLeft: $('.js-product-variety.active').position().left }, 600)

    $checkProductsModalRow.children().hide()
    const $activeAppended = $checkProductsModalRow.children(`[data-brand-id="${activeBrandId}"][data-group-id="${activeGroupId}"][data-variety-id="${activeVarietyId}"]`)
    $activeAppended.fadeIn(400)
    window.App.checkProducts()
    if ($activeAppended.length) return
    $.ajax({
      type: 'GET',
      url: '/paging/styles/products',
      dataType: 'script',
      data: {
        page: 0,
        brand_id: activeBrandId,
        group_id: activeGroupId,
        variety_id: activeVarietyId,
        site_id: siteId,
      },
    }).done(function() {
      window.App.checkProducts()
    })
    $('.js-check-products-modal-spinner').show()
  }

  const $checkProductsModal = $('.js-check-products-modal')
  $('.js-show-check-products-modal').on('click', function() {
    if ($styleProductRow.find('.js-style-product-col').length < 12) {
      $checkProductsModal.modal('show')
      updateView(true, true, true)
    }
  })

  const $checkProductModal = $('.js-check-product-modal')
  $checkProductModal.on('shown.bs.modal', function() {
    window.App.checkProducts()
  })
  $checkProductModal.on('hidden.bs.modal', function() {
    $('body').addClass('modal-open')
  })

  // product-brand
  $('.js-product-brand').on('click', function() {
    const newBrandId = $(this).data('brand-id')
    if (newBrandId === activeBrandId) return
    activeBrandId = newBrandId
    updateView()
  })

  // product-group
  $('.js-product-group').on('click', function() {
    const newGroupId = $(this).data('group-id')
    if (newGroupId === activeGroupId) return
    activeGroupId = newGroupId
    activeVarietyId = 0
    updateView(false, false, true)
  })

  // product-variety
  $('.js-product-variety').on('click', function() {
    const newVarietyId = $(this).data('variety-id')
    if (newVarietyId === activeVarietyId) return
    activeVarietyId = newVarietyId
    updateView()
  })

  const $spinnerModal = $('.js-spinner-modal')
  // check-product-col
  let currentProductId = null
  $(document).on('click', '.js-check-product-col', function() {
    const id = $(this).data('id')
    if (currentProductId === id) {
      $checkProductModal.modal('show')
    } else {
      getStyleProduct(id)
      currentProductId = id
    }
  })
  function getStyleProduct(id) {
    $.ajax({
      method: 'GET',
      url: ('/styles/products/' + id),
      dataType: 'script',
    })
    $spinnerModal.modal('show')
  }

  // style-product-row
  const $productAdditionDiv = $('.js-product-addition-col')
  function addProduct(productData) {
    const index = products.findIndex(product => product.id === productData.id)
    if (index >= 0) {
      products[index] = productData
    } else {
      products.push(productData)
    }
  }
  function removeProduct(id) {
    products = products.filter(product => product.id !== id)
  }
  function updateProductsCol() {
    $styleProductRow.find('.js-style-product-col').remove()
    products.forEach((product, idx) => {
      const colorSize = `${product.colorName || ''} ${product.sizeName || ''}`.trim()
      $styleProductRow.append(
        `<div class="style__product__col js-style-product-col" data-id="${product.id}">
          <div class="style__product__col__inner">
            <div class="style__product__col__added">
              <img src="${product.imgSrc}">
            </div>
            <div class="style__product__col__attrs">
              <div class="style__product__col__attrs__name">${product.name}</div>
              <div class="style__product__col__attrs__color-size">${colorSize}</div>
              <div class="style__product__col__attrs__price">${product.price}</div>
            </div>
          </div>
          <div class="style__product__col__remove js-product-remove-div">
            <p>×</p>
          </div>
          <input type="hidden" name="style[style_products_attributes][${idx}][product_id]" value="${product.id}">
          <input type="hidden" name="style[style_products_attributes][${idx}][product_color_id]" value="${product.colorId || ''}">
          <input type="hidden" name="style[style_products_attributes][${idx}][product_size_id]" value="${product.sizeId || ''}">
        </div>`
      )
    })

    if (11 < products.length) {
      $productAdditionDiv.hide()
    } else {
      $productAdditionDiv.show()
    }
  }
  updateProductsCol()
  $(document).on('click', '.js-check-product-color, .js-check-product-size', function() {
    $(this).siblings().attr('data-check', '0')
    $(this).attr('data-check', $(this).data('check') === '1' ? '0' : '1')
  })

  const $textModalBody = $('.js-text-modal-body')

  $(document).on('click', '.js-check-product-modal-btn', function() {
    const validationTextAry = []
    const id = $(this).data('id')
    if ($(`.js-check-product-color[data-product-id="${id}"]`).length && !$(`.js-check-product-color[data-product-id="${id}"][data-check="1"]`).length) {
      validationTextAry.push('カラーを選択してください')
    }
    if ($(`.js-check-product-size[data-product-id="${id}"]`).length && !$(`.js-check-product-size[data-product-id="${id}"][data-check="1"]`).length) {
      validationTextAry.push('サイズを選択してください')
    }
    if (validationTextAry.length) {
      $textModalBody.html(validationTextAry.map(function(text) {
        return `<p>${text}</p>`
      }).join(''))
      $textModal.modal('show')
      return
    }

    const $checkProductModalContent = $('.js-check-product-modal-content')
    $(`.js-check-product-col[data-id="${id}"] .js-check-product-check`).attr('data-check', '1')
    addProduct({
      id: id,
      name: $checkProductModalContent.find('.js-check-product-name').text(),
      imgSrc: $checkProductModalContent.find('.js-check-product-photo-img').attr('src'),
      price: $checkProductModalContent.find('.js-check-product-price').text(),
      colorId: $(`.js-check-product-color[data-product-id="${id}"][data-check="1"]`).data('product-color-id'),
      colorName: $(`.js-check-product-color[data-product-id="${id}"][data-check="1"]`).data('product-color-name'),
      sizeId: $(`.js-check-product-size[data-product-id="${id}"][data-check="1"]`).data('product-size-id'),
      sizeName: $(`.js-check-product-size[data-product-id="${id}"][data-check="1"]`).data('product-size-name'),
    })
    updateProductsCol()
    $checkProductModal.modal('hide')
    $checkProductsModal.modal('hide')
  })
  $(document).on('click', '.js-product-remove-div', function() {
    const id = $(this).closest('.js-style-product-col').data('id')
    $(`.js-check-product-col[data-id="${id}"] .js-check-product-check`).attr('data-check', '0')
    removeProduct(id)
    updateProductsCol()
  })

  // submit
  const $styleSnap = $('.js-style-snap'),
    $styleCaption = $('.js-style-caption'),
    $textModal = $('.js-text-modal')
  $('.js-upsert-style-btn, .js-draft-style-btn').on('click', function() {
    const validationTextAry = []
    if (!$styleSnap.val()) {
      validationTextAry.push('写真を追加してください')
    }
    if (!$styleCaption.val().trim()) {
      validationTextAry.push('投稿内容を入力してください')
    }
    if ($('.js-style-product-col').length === 0 && siteId) {
      validationTextAry.push('アイテムを追加してください')
    }

    if (validationTextAry.length) {
      $textModalBody.html(validationTextAry.map(function(text) {
        return `<p>${text}</p>`
      }).join(''))
      $textModal.modal('show')
    } else {
      if ($(this).hasClass('js-draft-style-btn')) {
        $('.js-is-display').val(false)
        Rails.fire(document.querySelector('.js-style-form'), 'submit')
      } else {
        const action = $('.js-style-form').attr('action')
        const confirmAction = action === '/styles' ? `${action}/create_confirm` : `${action}/update_confirm`
        $('.js-style-form').attr('action', confirmAction)
        $('.js-style-form').trigger('submit')
      }
      $spinnerModal.modal('show')
    }
  })
})
