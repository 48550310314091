Rails.start();
import 'bootstrap/dist/js/bootstrap';
import 'exif-js/exif';
import 'croppie/croppie';
import 'jquery-appear-original';
import 'devbridge-autocomplete/dist/jquery.autocomplete';
import jQueryBridget from 'jquery-bridget';
import Masonry from 'masonry-layout';
jQueryBridget('masonry', Masonry, $);
import imagesLoaded from 'imagesloaded';
imagesLoaded.makeJQueryPlugin($);