$(function() {
  if ($('body.all-asks.index').length < 1) return

  const siteId = $('.js-home-sites').data('site-id')
  const $listAsks = $('.js-list-asks')
  let activeProposable = $listAsks.data('proposable')

  function updateView() {
    $('.js-ask-proposable').removeClass('active')
    $(`.js-ask-proposable[data-proposable="${activeProposable}"]`).addClass('active')

    $listAsks.children().hide()
    const $activeAppended = $listAsks.children(`[data-proposable="${activeProposable}"]`)
    $activeAppended.fadeIn(400)
    if ($activeAppended.length) return
    $.ajax({
      type: 'GET',
      url: '/paging/asks',
      dataType: 'script',
      data: {
        page: 0,
        site_id: siteId,
        proposable: activeProposable,
      },
    })
    $('.js-infinite-spinner').show()
  }

  // ask-proposable
  $('.js-ask-proposable').on('click', function() {
    const newProposable = $(this).data('proposable')
    if (newProposable === activeProposable) return
    activeProposable = newProposable
    updateView()
  })

  // init
  $(`.js-home-site[data-site-id="${siteId}"]`).addClass('active')
  updateView()
})
