$(function() {
  if ($('body.sites-privileges.index').length < 1) return

  let activeSiteId = 0

  const $listPrivileges = $('.js-list-privileges')
  function updateView() {
    $('.js-privilege-site').removeClass('active')
    $(`.js-privilege-site[data-site-id="${activeSiteId}"]`).addClass('active')

    $listPrivileges.children().hide()
    const $activeAppended = $listPrivileges.children(`[data-site-id="${activeSiteId}"]`)
    $activeAppended.fadeIn(400)
    if ($activeAppended.length) return
    $.ajax({
      type: 'GET',
      url: '/paging/sites/privileges',
      dataType: 'script',
      data: {
        page: 0,
        site_id: activeSiteId,
      },
    })
    $('.js-infinite-spinner').show()
  }

  // privilege-site
  $('.js-privilege-site').on('click', function() {
    const newSiteId = $(this).data('site-id')
    if (newSiteId === activeSiteId) return
    activeSiteId = newSiteId
    updateView()
  })

  // init
  updateView()
})
