$(function() {
  if ($('body.users-passwords.edit').length < 1) return

  // inputs
  const $password = $('.js-password')

  // update-btn
  const $textModal = $('.js-text-modal'),
    $textModalBody = $('.js-text-modal-body')
  $('.js-update-btn').on('click', function() {
    const validationTextAry = []
    if (!$password.val()) {
      validationTextAry.push('パスワードを入力してください')
    } else if ($password.val().length < 8) {
      validationTextAry.push('パスワードは8文字以上入力してください')
    }

    if (validationTextAry.length) {
      $textModalBody.html(validationTextAry.map(function(text) {
        return `<p>${text}</p>`
      }).join(''))
      $textModal.modal('show')
    } else {
      $.ajax({
        method: 'PATCH',
        url: '/users/password',
        dataType: 'script',
        data: {
          user: {
            password: $password.val(),
            reset_password_token: new URLSearchParams(window.location.search).get('reset_password_token'),
          },
        },
      })
      $('.js-spinner-modal').modal('show')
    }
  })
})
