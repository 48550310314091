import { v4 as uuidv4 } from 'uuid'

const reloadPathAry = [/^\/all\/notifications/, /^\/asks\/\d+\/proposals\/collect/]
window.addEventListener('pageshow', function(e) {
  $('.js-spinner-modal').modal('hide')

  const isBackForward = e.persisted || (window.performance.getEntriesByType('navigation')[0] && window.performance.getEntriesByType('navigation')[0].type === 'back_forward')
  if (isBackForward && reloadPathAry.some(path => path.test(location.pathname))) {
    window.location.reload()
  }
})

$(function() {
  $('.js-alert').delay(4000).slideUp()

  $('.js-back-link').on('click', function() {
    const backLinkPath = $(this).data('path')
    if (backLinkPath) {
      window.location.href = backLinkPath
    } else if (1 < window.history.length) {
      window.history.back()
    } else {
      window.location.href = '/'
    }
  })

  if (!App.util.localStorageGetItem('clientId')) {
    App.util.localStorageSetItem('clientId', uuidv4())
  }

  // embed-site-link
  $('.js-embed-site-link').on('click', function() {
    const clientId = App.util.localStorageGetItem('clientId')
    if (clientId) {
      const $this = $(this)
      const href = $this.attr('href')
      if (href.startsWith('http')) {
        const url = new URL(href)
        url.searchParams.set('stylista_client_id', clientId)
        $this.attr('href', url.toString())
      }
    }
  })
})
